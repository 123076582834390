import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Screen from 'components/templates/Screen.js';
import PropTypes from 'prop-types';
import instance from 'axiosInstance';
import { withRouter } from 'react-router-dom';
import NetworkRow from 'components/network/NetworkRow';

export class ManageNetwork extends Component {
  constructor (props) {
    super(props);
    this.state = { loading: true, relationships: [], requestsSent: [], requestsReceived: [], candidates: [], id: 0 };
    this.getRelationships = this.getRelationships.bind(this);
  }

  getRelationships () {
    return instance.get('/relationships/dashboard').then(s => {
      this.setState({ loading: false, relationships: s.data.relationships, requestsSent: s.data.requestsSent, requestsReceived: s.data.requestsReceived, candidates: s.data.candidates, id: s.data.id });
    });
  }

  componentDidMount () {
    this.getRelationships();
  }

  render () {
    const { t } = this.props;
    const { relationships, requestsReceived, candidates, requestsSent, id } = this.state;
    return (
      <Screen type="gray" loading={this.state.loading} title={t('manageNetwork')}>
        <div className="container tw-space-y-3">
          <div className="row">
            <div className="col-lg-6 tw-mx-auto">
              <div className="tw-card">
                <h2 className="tw-text-xl tw-font-bold">{t('requestsReceived')}</h2>
                {requestsReceived.map(r => {
                  return (
                    <NetworkRow update={this.getRelationships} setLoading={() => { this.setState({ loading: true }); }} type="received" key={r.id} relId={r.id} id={id} candidateId={r.sender_id} candidates={candidates} />
                  );
                })}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 tw-mx-auto">
              <div className="tw-card">
                <h2 className="tw-text-xl tw-font-bold">{t('relationships')}</h2>
                {relationships.map(r => {
                  return (
                    <NetworkRow update={this.getRelationships} setLoading={() => { this.setState({ loading: true }); }} type="relationship" key={r.id} relId={r.id} id={id} candidateId={(parseInt(r.candidate_id1) !== parseInt(id) ? r.candidate_id1 : r.candidate_id2)} candidates={candidates} />
                  );
                })}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 tw-mx-auto">
              <div className="tw-card">
                <h2 className="tw-text-xl tw-font-bold">{t('requestsSent')}</h2>
                {requestsSent.map(r => {
                  return (
                    <NetworkRow update={this.getRelationships} setLoading={() => { this.setState({ loading: true }); }} type="sent" key={r.id} relId={r.id} id={id} candidateId={r.receiver_id} candidates={candidates} />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </Screen>
    );
  }
}

ManageNetwork.propTypes = {
  t: PropTypes.func.isRequired
};

export default withRouter(withTranslation('common')(ManageNetwork));
