import React, { Component } from 'react';
import Screen from 'components/templates/Screen';
import { withTranslation } from 'react-i18next';
import emailSent from 'img/emailSent.svg';
import error from 'img/error.svg';
import instance from 'axiosInstance';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';

export class NewEmailVerified extends Component {
  constructor () {
    super();
    this.state = { loading: true, success: false, error: '' };
  }

  componentDidMount () {
    if (!this.props?.location?.search) {
      this.props.history.push('/');
    }
    const params = queryString.parse(this.props.location.search);
    var fd = new FormData();
    fd.append('unique_code', params?.code);
    instance.post('/new-email', fd)
      .then(success => {
        this.setState({ loading: false, success: true });
      }, error => {
        this.setState({ loading: false, success: false, error: error.response.data.action });
      });
  }

  getContent () {
    if (this.state.success === true) {
      return (
        <div style={{ width: '500px', position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} className="tw-flex tw-flex-col tw-justify-center tw-space-y-4 tw-items-center tw-text-center tw-card tw-p-4">
          <img style={{ height: '200px' }} src={emailSent} />
          <p>{this.props.t('newEmailVerified')}</p>
          <div onClick={() => { this.props.history.push('/'); }} className="tw-border-aa-yellow tw-border-4 tw-p-3 tw-py-1  tw-font-bold hover:tw-bg-aa-yellow tw-cursor-pointer">
            <p>{this.props.t('common:backToHome')}</p>
          </div>
        </div>

      );
    } else {
      return (
        <div style={{ width: '500px', position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} className="tw-flex tw-flex-col tw-justify-center tw-space-y-4 tw-items-center tw-text-center tw-card tw-p-4">
          <img style={{ height: '200px' }} src={error} />
          <p>{this.props.t(this.state.error)}</p>
          <div onClick={() => { this.props.history.push('/'); }} className="tw-border-aa-yellow tw-border-4 tw-p-3 tw-py-1  tw-font-bold hover:tw-bg-aa-yellow tw-cursor-pointer">
            <p>{this.props.t('common:backToHome')}</p>
          </div>
        </div>
      );
    }
  }

  render () {
    return (
      <Screen type="gray" loading={this.state.loading} containerClasses="tw-h-full tw-flex tw-flex-row tw-justify-center tw-items-center" title={this.props.t('pages:newEmailVerified')}>
        {this.getContent()}
      </Screen>
    );
  }
}

export default withRouter(withTranslation(['common', 'pages'])(NewEmailVerified));
