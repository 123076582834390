import React, { Component } from 'react';
import PropTypes from 'prop-types';

export class Button extends Component {
  render () {
    return (
      <button {...this.props} onClick={this.props.action} className="focus:tw-bg-yellow-700 tw-bg-aa-yellow tw-px-3 tw-py-2 tw-font-bold tw-rounded-lg tw-text-sm tw-m-1 tw-self-end tw-leading-none tw-min-w-32 tw-text-black tw-cursor-pointer tw-flex tw-flex-row focus:tw-outline-none ">

        {this.props.icon && <img src={this.props.icon || ''} alt="Icon" className="tw-h-4 tw-mr-1" />}
        {this.props.text}
      </button>
    );
  }
}

Button.defaultProps = {
  action: () => {},
  text: 'Default Text'
};

Button.propTypes = {
  action: PropTypes.func,
  text: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired
};

export default Button;
