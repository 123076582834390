import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { objt } from 'utils';
import Button from 'components/common/Button';
import Screen from 'components/templates/Screen.js';
import PropTypes from 'prop-types';
import instance from 'axiosInstance';
import { withRouter } from 'react-router-dom';
import profile from 'img/placeholder/profile.svg';

export class MyProfile extends Component {
  constructor (props) {
    super(props);
    this.state = { candidate: {}, loading: true };
  }

  componentDidMount () {
    instance.get('/candidate/details').then((s) => {
      this.setState({ candidate: s.data.candidate, loading: false });
    });
  }

  getCV () {
    this.setState({ loading: true });
    instance.get('/candidate/cv', { responseType: 'blob' }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'cv.pdf');
      document.body.appendChild(link);
      link.click();
      this.setState({ loading: false });
    });
  }

  getVideoCV () {
    this.setState({ loading: true });
    instance.get('/candidate/videoCV', { responseType: 'blob' }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', this.state.candidate.video_cv.substr(10));
      document.body.appendChild(link);
      link.click();
      this.setState({ loading: false });
    });
  }

  displayData (data) {
    if (Array.isArray(data)) {
      if (data.length === 0) {
        return this.props.t('undefined');
      }
      return data.map(datum => objt(datum)).join(', ');
    } else if (typeof data === 'object') {
      return objt(data);
    }
  }

  displayYesNo (data) {
    if (data == 1) {
      return this.props.t('yes');
    } else {
      return this.props.t('no');
    }
  }

  displayWorkExperiences (data) {
    if (data !== undefined && Array.isArray(JSON.parse(data))) {
      data = JSON.parse(data);
      if (data.length === 0) {
        return this.props.t('undefined');
      }
      return (
        <ul className="tw-list-disc tw-pl-6">
          {data.map(datum =>
            <li key={datum}>{datum}</li>
          )}
        </ul>
      );
    }
  }

  displayTrainings (data) {
    if (data !== undefined && Array.isArray(data)) {
      if (data.length === 0) {
        return this.props.t('undefined');
      }
      return (
        <ul className="tw-list-disc tw-pl-6">
          {data.map(datum =>
            <li key={datum}>{objt(datum)}, {datum.pivot.description}</li>
          )}
        </ul>
      );
    }
  }

  getProfilePic () {
    const { candidate } = this.state;
    if (!candidate.profile_pic) {
      return profile;
    }
    return (process.env.REACT_APP_STORAGE_URL + candidate.profile_pic).replace(/([^:]\/)\/+/g, '$1');
  }

  getVideoCvStatus () {
    const { t } = this.props;
    const { candidate } = this.state;
    if (candidate.video_cv_status === 'pending') {
      return t('pending');
    } else if (candidate.video_cv_status === 'accepted') {
      return t('accepted');
    } else if (candidate.video_cv_status === 'refused') {
      return t('refused');
    }
  }

  render () {
    const { t } = this.props;
    const { candidate } = this.state;
    return (
      <Screen loading={this.state.loading} type="gray" title={this.props.t('pages:myProfile')}>
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="tw-card tw-items-center tw-flex tw-flex-col tw-mb-4">
                <div alt="Profile Picture" className=" tw-h-24 tw-w-24 tw-bg-cover tw-bg-center">
                  <img src={this.getProfilePic()} className="tw-w-full tw-h-full tw-object-cover " />
                </div>
                <p className="tw-text-xl tw-font-bold">{ candidate.first_name + ' ' + candidate.last_name}</p>
                <p>{ candidate.email }</p>
                <p>{ candidate.phone_number }</p>
                <p>{ `${t('searching')}: ${(candidate.searching == '0' ? t('no') : t('yes'))}` }</p>
              </div>
              <div onClick={() => { this.props.history.push('/profile/network'); }}className="tw-bg-blue-400 tw-h-16 tw-full tw-flex tw-flex-row tw-justify-center tw-items-center  tw-cursor-pointer focus:tw-bg-gray-700 tw-shadow-lg">
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24"><path d="M6.188 8.719c.439-.439.926-.801 1.444-1.087 2.887-1.591 6.589-.745 8.445 2.069l-2.246 2.245c-.644-1.469-2.243-2.305-3.834-1.949-.599.134-1.168.433-1.633.898l-4.304 4.306c-1.307 1.307-1.307 3.433 0 4.74 1.307 1.307 3.433 1.307 4.74 0l1.327-1.327c1.207.479 2.501.67 3.779.575l-2.929 2.929c-2.511 2.511-6.582 2.511-9.093 0s-2.511-6.582 0-9.093l4.304-4.306zm6.836-6.836l-2.929 2.929c1.277-.096 2.572.096 3.779.574l1.326-1.326c1.307-1.307 3.433-1.307 4.74 0 1.307 1.307 1.307 3.433 0 4.74l-4.305 4.305c-1.311 1.311-3.44 1.3-4.74 0-.303-.303-.564-.68-.727-1.051l-2.246 2.245c.236.358.481.667.796.982.812.812 1.846 1.417 3.036 1.704 1.542.371 3.194.166 4.613-.617.518-.286 1.005-.648 1.444-1.087l4.304-4.305c2.512-2.511 2.512-6.582.001-9.093-2.511-2.51-6.581-2.51-9.092 0z"/></svg>
                <span className="tw-font-bold tw-text-xl tw-ml-4">{t('manageNetwork')}</span>
              </div>
            </div>
            <div className="col-lg-8 tw-h-64">
              <div className="tw-card">
                <div className="tw-flex tw-flex-row tw-justify-between tw-items-center">
                  <h2 className="tw-text-xl tw-font-bold">{t('generalInformations')}</h2>
                  <Button action={() => { this.props.history.push('/modify-profile'); }}text={t('pages:modifyProfile')} />
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <h3 className="tw-text-lg tw-font-semibold">{t('first_name')}</h3>
                    <p>{ candidate.first_name }</p>
                    <h3 className="tw-text-lg tw-font-semibold">{t('email')}</h3>
                    <p>{ candidate.email }</p>
                    <h3 className="tw-text-lg tw-font-semibold">{t('postal')}</h3>
                    <p>{ candidate.postal }</p>
                    <h3 className="tw-text-lg tw-font-semibold">{t('searching')}</h3>
                    <p>{ candidate.searching == '0' ? t('no') : t('yes') }</p>
                  </div>
                  <div className="col-lg-6">
                    <h3 className="tw-text-lg tw-font-semibold">{t('last_name')}</h3>
                    <p>{ candidate.last_name }</p>
                    <h3 className="tw-text-lg tw-font-semibold">{t('phone_number')}</h3>
                    <p>{ candidate.phone_number }</p>
                    <h3 className="tw-text-lg tw-font-semibold">{t('city')}</h3>
                    <p>{ candidate.city }</p>
                    <h3 className="tw-text-lg tw-font-semibold">Description/bio</h3>
                    <p>{ candidate.bio }</p>

                  </div>
                </div>
              </div>
              <div className="tw-card tw-mt-3">
                <div className="tw-flex tw-flex-row tw-justify-between tw-items-center">
                  <h2 className="tw-text-xl tw-font-bold">{t('professionalInformations')}</h2>
                  <Button action={() => { this.props.history.push('/modify-profile'); }}text={t('pages:modifyProfile')} />
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <h3 className="tw-text-lg tw-font-semibold">{t('interests')}</h3>
                    <p>{ this.displayData(candidate.interests) }</p>
                    <h3 className="tw-text-lg tw-font-semibold">{t('field_of_work')}</h3>
                    <p>{ this.displayData(candidate.fields_of_work) }</p>
                    <h3 className="tw-text-lg tw-font-semibold">{t('work_experiences')}</h3>
                    { this.displayWorkExperiences(candidate.work_experiences) }
                    <h3 className="tw-text-lg tw-font-semibold">{t('training')}</h3>
                    { this.displayTrainings(candidate.trainings) }
                    <h3 className="tw-text-lg tw-font-semibold">{t('driver')}</h3>
                    <p>{ this.displayYesNo(candidate.driver) }</p>
                    <h3 className="tw-text-lg tw-font-semibold">{t('work_permit')}</h3>
                    <p>{ this.displayYesNo(candidate.work_permit) }</p>
                    <h3 className="tw-text-lg tw-font-semibold">{t('permanence')}</h3>
                    <p>{ this.displayYesNo(candidate.permanence) }</p>

                  </div>
                  <div className="col-lg-6">
                    <h3 className="tw-text-lg tw-font-semibold">{t('salary_id')}</h3>
                    <p>{ this.displayData(candidate.salary) }</p>
                    <h3 className="tw-text-lg tw-font-semibold">{t('job_type')}</h3>
                    <p>{ this.displayData(candidate.job_types) }</p>
                    <h3 className="tw-text-lg tw-font-semibold">{t('availability')}</h3>
                    <p>{ this.displayData(candidate.availabilities) }</p>
                    <h3 className="tw-text-lg tw-font-semibold">{t('car')}</h3>
                    <p>{ this.displayYesNo(candidate.car) }</p>
                    <h3 className="tw-text-lg tw-font-semibold">{t('student_permit')}</h3>
                    <p>{ this.displayYesNo(candidate.student_permit) }</p>
                    <h3 className="tw-text-lg tw-font-semibold">{t('citizen')}</h3>
                    <p>{ this.displayYesNo(candidate.citizen) }</p>

                  </div>
                </div>
              </div>
              <div className="tw-card tw-mt-3">
                <div className="tw-flex tw-flex-row tw-justify-between tw-items-center">
                  <h2 className="tw-text-xl tw-font-bold">{t('documents')}</h2>
                  <Button action={() => { this.props.history.push('/modify-profile'); }}text={t('pages:modifyProfile')} />
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <h3 className="tw-text-lg tw-font-semibold">{t('cvF')}</h3>
                    {(candidate.cv)
                      ? <a onClick={() => { this.getCV(); }} className="tw-text-blue-700 tw-underline tw-cursor-pointer">{t('downloadCV')}</a>
                      : <p>{t('noCV')}</p>}

                  </div>
                  <div className="col-lg-6">
                    <h3 className="tw-text-lg tw-font-semibold">{t('video_cv')}</h3>
                    {(candidate.video_cv)
                      ? <a onClick={() => { this.getVideoCV(); }} className="tw-text-blue-700 tw-underline tw-cursor-pointer">{t('downloadVideoCV')}</a>
                      : <p>{t('noVideoCV')}</p>}
                    {(candidate.video_cv_status !== 'no video') &&
                      <p>{t('status')}: {this.getVideoCvStatus()}</p>
                    }
                  </div>
                </div>
              </div>

            </div>
          </div>

        </div>
      </Screen>
    );
  }
}

MyProfile.propTypes = {
  t: PropTypes.func.isRequired
};

export default withRouter(withTranslation('common')(MyProfile));
