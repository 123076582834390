import React, { Component } from 'react';
import messages from 'img/icons/phone.svg';
import messagesNotif from 'img/icons/phoneNotif.svg';
import settings from 'img/icons/settings.svg';
import back from 'img/icons/back.svg';
import home from 'img/icons/home.svg';
import logout from 'img/icons/logout.svg';
import menu from 'img/icons/menu.svg';
import shop from 'img/icons/shop.svg';
import profile from 'img/icons/profile.svg';
import close from 'img/icons/close.svg';
import cart from 'img/icons/cart.svg';
import bell from 'img/icons/bell.svg';
import PropTypes from 'prop-types';
import ReactLoading from 'react-loading';
import { withRouter } from 'react-router-dom';
import * as Cookies from 'js-cookie';
import { withTranslation } from 'react-i18next';
import { isAuth, getAmountInCart } from 'utils';
import instance from 'axiosInstance';
import Notifications from 'components/social/Notifications';

export class Screen extends Component {
  constructor () {
    super();
    this.getBackground = this.getBackground.bind(this);
    this.getNotifs = this.getNotifs.bind(this);
    this.state = { seen: false, messaging: 0, posts: [], notifications: false };
  }

  componentDidMount () {
    if (isAuth()) {
      this.getNotifs();
      setInterval(() => { this.getNotifs(); }, 120000);
    }
  }

  getNotifs () {
    instance.get('/notifs').then(s => {
      this.setState({ messaging: s.data.messaging, posts: s.data.posts });
    });
  }

  getBackground () {
    var type = this.props.type !== undefined ? this.props.type : 'bureaux';
    return 'bg-' + type;
  }

  getPadding () {
    return this.props.padding === false ? '' : this.props.paddingTop === false ? ' pb-3' : ' py-3 ';
  }

  render () {
    if (this.props.loading && this.props.loading === true) {
      return (
        <div className="tw-w-full tw-h-screen tw-flex tw-flex-row tw-justify-center tw-items-center" style={{ backgroundColor: '#434349' }}>
          <ReactLoading type="spin" color="#ECC94B" />
        </div>
      );
    }
    return (
      <div className={this.getBackground() + ' tw-h-screen tw-w-screen tw-grid tw-grid-rows-layout'}>
        <Notifications opened={this.state.notifications} posts={this.state.posts} updateNotifs={this.getNotifs} handle={(s) => {
          this.setState({ notifications: s });
        }} />
        <div className="tw-flex tw-flex-row tw-justify-between tw-items-center tw-font-display" style={{ backgroundColor: '#434349' }}>
          <div className="tw-flex tw-w-1/8 md:tw-w-1/3 tw-justify-start md:tw-space-x-4 md:tw-pl-8 tw-pl-4 tw-flex-row">
            <img src={back} onClick={() => {
              this.props.history.goBack();
            }} className="tw-hidden md:tw-block tw-h-6 tw-cursor-pointer" />
            <img src={home} onClick={() => {
              this.props.history.push('/');
            }} className="tw-h-6 tw-cursor-pointer" />
          </div>

          {this.props.titleComp ? this.props.titleComp
            : <h1 className="tw-w-1/3 tw-text-center tw-pl-8 md:tw-px-0 tw-whitespace-no-wrap tw-font-bold tw-text-xl tw-text-white">
              {this.props.title}
            </h1>}

          <div className="tw-hidden md:tw-flex tw-w-1/3 tw-justify-end tw-space-x-4 tw-pr-8 tw-flex-row">
            {isAuth() &&
            <div className="tw-relative">
              <img src={bell} className="tw-h-6 tw-cursor-pointer" onClick={() => {
                this.setState({ notifications: true });
              }}/>
              {this.state.posts.length > 0 &&
              <span style={{ borderRadius: '50%', padding: '0px 6px', top: -6, right: -6 }} className="tw-text-sm tw-absolute aa-badge tw-bg-aa-yellow tw-text-black">{this.state.posts.length}</span>
              }
            </div>}
            {isAuth() &&
            <div className="tw-relative">
              <img src={messages} className="tw-h-6 tw-cursor-pointer" onClick={() => {
                this.props.history.push('/messaging');
              }}/>
              {this.state.messaging > 0 &&
              <span style={{ borderRadius: '50%', padding: '0px 6px', top: -6, right: -6 }} className="tw-text-sm tw-absolute aa-badge tw-bg-aa-yellow tw-text-black">{this.state.messaging}</span>
              }
            </div>}
            {isAuth() && <img src={profile} className="tw-h-6 tw-cursor-pointer" onClick={() => {
              this.props.history.push('/dashboard');
            }}/>}
            <img src={shop} className="tw-h-6 tw-cursor-pointer" onClick={() => {
              this.props.history.push('/shop');
            }}/>
            <div className="tw-relative">
              <img src={cart} onClick={() => {
                this.props.history.push('/cart');
              }} className="tw-h-6 tw-cursor-pointer" />
              {((this.props.cartSize && this.props.cartSize > 0) || getAmountInCart() > 0) &&
              <span style={{ borderRadius: '50%', padding: '0px 6px', top: -6, right: -6 }} className="tw-text-sm tw-absolute aa-badge tw-bg-aa-yellow tw-text-black">{(this.props.cartSize && this.props.cartSize > 0) ? this.props.cartSize : getAmountInCart()}</span>
              }
            </div>
            {Cookies.get('auth') && <img src={logout} className="tw-h-6 tw-cursor-pointer" onClick={() => {
              this.props.history.push('/logout-page');
            }}/>}

          </div>

          <img src={menu} className="tw-block md:tw-hidden tw-h-6 tw-cursor-pointer tw-pr-8 tw-cursor-pointer" onClick={() => {
            this.setState({ menuOpened: true });
          }}/>

          {this.state.menuOpened && (
            <div className="tw-h-screen tw-space-y-4 tw-z-50 tw-w-screen tw-absolute tw-bg-black tw-top-0 tw-bottom-0 tw-left-0 tw-right-0 tw-flex tw-flex-col tw-justify-center tw-items-center">
              <img style={{ top: '1.25rem', right: '2rem' }} src={close} className="tw-block md:tw-hidden tw-h-10 tw-cursor-pointer tw-right-8 tw-absolute tw-top-8" onClick={() => {
                this.setState({ menuOpened: false });
              }}/>
              <p onClick={() => { this.props.history.push('/'); }} className="tw-text-white tw-text-xl hover:tw-underline">
                {this.props.t('home')}
              </p>
              <p onClick={() => { this.props.history.push('/job-offers'); }} className="tw-text-white tw-text-xl hover:tw-underline">
                {this.props.t('jobs')}
              </p>
              {isAuth() && <p onClick={() => {
                this.setState({ notifications: true });
              }} className="tw-text-white tw-text-xl hover:tw-underline">
                {this.props.t('common:notifications')}
              </p>}
              {isAuth() && <p onClick={() => { this.props.history.push('/dashboard'); }} className="tw-text-white tw-text-xl hover:tw-underline">
                {this.props.t('profile')}
              </p>}
              {isAuth() && <p onClick={() => { this.props.history.push('/messaging'); }} className="tw-text-white tw-text-xl hover:tw-underline">
                {this.props.t('messages')}
              </p>}
              <p onClick={() => { this.props.history.push('/shop'); }} className="tw-text-white tw-text-xl hover:tw-underline">
                {this.props.t('trainings')}
              </p>
              <p onClick={() => { this.props.history.push('/cart'); }} className="tw-text-white tw-text-xl hover:tw-underline">
                {this.props.t('purchases')}
              </p>

              {isAuth() && <p onClick={() => { this.props.history.push('/logout-page'); }} className="tw-text-white tw-text-xl hover:tw-underline">
                {this.props.t('logout')}
              </p>}
            </div>)}

        </div>
        <div onScroll={this.props.handleScroll || function () {}}className={'w-full tw-overflow-y-auto' + this.getPadding() + this.props.containerClasses}>
          {this.props.children}
        </div>

      </div>
    );
  }
}

Screen.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  containerClasses: PropTypes.string,
  loading: PropTypes.bool
};

export default withRouter(withTranslation(['pages', 'common'])(Screen));
