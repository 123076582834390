import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { formToFormData } from 'utils';
import Screen from 'components/templates/Screen.js';
import PropTypes from 'prop-types';
import instance from 'axiosInstance';
import TextInput from 'components/forms/TextInput';
import Form from 'components/forms/Form';
import FileInput from 'components/forms/FileInput';
import Submit from 'components/forms/Submit';
import { connect } from 'react-redux';
import { addErrors, handleChange, listAdd, listDescAdd, clearForm } from 'redux/actions';
import { withRouter } from 'react-router-dom';
import TextareaInput from 'components/forms/TextareaInput';
import ReactLoading from 'react-loading';

export class CreatePost extends Component {
  constructor () {
    super();
    this.state = { loading: false };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit (e) {
    e.preventDefault();
    this.setState({ loading: true });
    var fd = formToFormData(['text', 'video_link', 'images[]'], this.props.form);
    instance.post('/posts', fd).then((success) => {
      this.props.clearForm('create-post');
      this.props.closeModal();
      if(this.props?.profile) {
        this.props.history.push('/profile/' + this.props.profile + '/posts/' + success.data.postId);
      } else {
        this.props.history.push('/posts/' + success.data.postId);
      }
      window.location.reload();
    }, (error) => {
      this.props.addErrors(error.response.data.errors, 'create-post');
    }).then(() => {
      this.setState({ loading: false });
    });
  }

  render () {
    const { t } = this.props;
    if (this.props.opened === true) {
      return (
        <div style={{ minHeight: '40vh', minWidth: '50vw', position: 'absolute', backgroundColor: 'rgba(0, 0, 0, 0.7)' }} className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-inset-0">
          {this.state.loading === true
            ? <ReactLoading type="spin" color="#ECC94B" />
            : <Form onSubmit={this.handleSubmit} name="create-post" classes="md:tw-w-1/2 tw-w-full tw-z-50" classesOverwrite={true}>
              <div className="tw-card tw-mt-3">
                <div className="tw-flex tw-flex-row tw-justify-between tw-items-center">
                  <h2 className="tw-text-xl tw-font-bold">{t('pages:createPost')}</h2>
                </div>
                <TextareaInput name="text" />
                <TextInput required="false" name="video_link" />
                {this.props.form['images[]'] && Array.from(this.props.form['images[]']).map(i => (
                  <img className="tw-h-24 tw-my-2" src={URL.createObjectURL(i)} />
                ))}
                <FileInput required="false" name="images[]" multiple={true} />
                <div className="tw-flex tw-flex-row tw-justify-end tw-py-3">
                  <button type="button" onClick={() => { this.props.closeModal(); }} className="focus:tw-bg-red-700 tw-bg-red-500  tw-px-3 tw-py-3 tw-font-bold  tw-text-sm tw-m-1 tw-self-end tw-leading-none tw-min-w-32 tw-text-black tw-cursor-pointer focus:tw-outline-none focus:tw-shadow-outline">
                    {this.props.t('close')}
                  </button>

                  <Submit />
                </div>
              </div>
            </Form>}
        </div>
      );
    }
    return null;
  }
}

CreatePost.propTypes = {
  t: PropTypes.func.isRequired
};

CreatePost.defaultProps = {
  form: {}
};

const mapStateToProps = ({ input }) => {
  return {
    form: input['create-post']
  };
};

export default withRouter(connect(mapStateToProps, { addErrors, handleChange, listAdd, listDescAdd, clearForm })(withTranslation('common')(CreatePost)));
