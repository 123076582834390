import React, { Component } from 'react';
import Screen from 'components/templates/Screen';
import { withTranslation } from 'react-i18next';
import emailSent from 'img/emailSent.svg';
import { withRouter } from 'react-router-dom';
import * as Cookies from 'js-cookie';

export class EmailVerified extends Component {
  pushHistory () {
    if (Cookies.get('origin') !== undefined) {
      var origin = Cookies.get('origin');
      Cookies.remove('origin');
      this.props.history.push(origin);
    } else {
      this.props.history.push('/');
    }
  }

  render () {
    Cookies.set('email_verified', true);
    return (
      <Screen type="gray" containerClasses="tw-h-full tw-flex tw-flex-row tw-justify-center tw-items-center" title={this.props.t('pages:emailVerified')}>
        <div style={{ width: '500px', position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} className="tw-flex tw-flex-col tw-justify-center tw-space-y-4 tw-items-center tw-text-center tw-card tw-p-4">
          <img style={{ height: '200px' }} src={emailSent} />
          <p>{this.props.t('emailVerified')}</p>
          <div onClick={() => { this.pushHistory(); }} className="tw-border-aa-yellow tw-border-4 tw-p-3 tw-py-1  tw-font-bold hover:tw-bg-aa-yellow tw-cursor-pointer">
            <p>{this.props.t('common:backToHome')}</p>
          </div>
        </div>
      </Screen>
    );
  }
}

export default withRouter(withTranslation(['common', 'pages'])(EmailVerified));
