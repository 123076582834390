import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Screen from 'components/templates/Screen.js';
import PropTypes from 'prop-types';
import instance from 'axiosInstance';
import { withRouter } from 'react-router-dom';
import PicturesUploadModal from './PicturesUploadModal';

export class ManagePictures extends Component {
  constructor (props) {
    super(props);
    this.state = { images: [], loading: true, modalOpen: false, files: [], error: false };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.deleteImage = this.deleteImage.bind(this);
  }

  componentDidMount () {
    instance.get('/candidate/details').then((s) => {
      this.setState({ images: s.data.candidate.images, loading: false });
    });
  }

  handleChange (e) {
    this.setState({ files: e.target.files });
  }

  handleSubmit () {
    this.setState({ loading: true });
    var fd = new FormData();
    for (var i = 0; i < this.state.files.length; i++) {
      fd.append('images[]', this.state.files[i]);
    }

    instance.post('/candidate/attachImages', fd).then(s => {
      this.setState({ modalOpen: false, loading: false });
      window.location.reload();
    }, e => {
      this.setState({ loading: false, error: true });
    });
  }

  deleteImage (e, id) {
    e.preventDefault();
    if (window.confirm(this.props.t('deleteImage'))) {
      this.setState({ loading: true });
      instance.get('/candidate/deleteImage/' + id).then(s => {
        window.location.reload();
      });
    }
  }

  render () {
    const { t } = this.props;
    const { images } = this.state;
    return (
      <Screen type="gray" loading={this.state.loading} title={t('managePictures')}>
        <div className="container">
          <div className="row">
            <PicturesUploadModal modalOpen={this.state.modalOpen} handleChange={this.handleChange} handleSubmit={this.handleSubmit} images={images} handleClose={() => { this.setState({ modalOpen: false }); }} error={this.state.error} />
            {images !== undefined && images.length !== 0 && images.map(image => (
              <div key={image.id} className="col-lg-3 tw-mb-6" style={{ height: 200 }}>
                <svg onClick={(e) => { this.deleteImage(e, image.id); }}className="tw-fill-current tw-text-red-500 tw-absolute tw-top-4 tw-right-4" style={{ top: '0.25rem', right: '1.25rem' }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm4.151 17.943l-4.143-4.102-4.117 4.159-1.833-1.833 4.104-4.157-4.162-4.119 1.833-1.833 4.155 4.102 4.106-4.16 1.849 1.849-4.1 4.141 4.157 4.104-1.849 1.849z"/></svg>
                <img className="tw-h-full tw-w-full tw-object-cover " src={(process.env.REACT_APP_STORAGE_URL + image.path).replace(/([^:]\/)\/+/g, '$1')} />
              </div>
            ))}
            <div className="col-lg-3" style={{ height: 200 }}>
              <div onClick={() => { this.setState({ modalOpen: true }); }}className="tw-bg-gray-300 tw-h-full tw-flex tw-flex-col tw-justify-center tw-items-center  tw-cursor-pointer focus:tw-bg-gray-700 tw-shadow-lg">
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24"><path d="M5 4h-3v-1h3v1zm10.93 0l.812 1.219c.743 1.115 1.987 1.781 3.328 1.781h1.93v13h-20v-13h3.93c1.341 0 2.585-.666 3.328-1.781l.812-1.219h5.86zm1.07-2h-8l-1.406 2.109c-.371.557-.995.891-1.664.891h-5.93v17h24v-17h-3.93c-.669 0-1.293-.334-1.664-.891l-1.406-2.109zm-11 8c0-.552-.447-1-1-1s-1 .448-1 1 .447 1 1 1 1-.448 1-1zm7 0c1.654 0 3 1.346 3 3s-1.346 3-3 3-3-1.346-3-3 1.346-3 3-3zm0-2c-2.761 0-5 2.239-5 5s2.239 5 5 5 5-2.239 5-5-2.239-5-5-5z"/></svg>
                <span className="tw-font-bold tw-text-xl">{t('addPictures')}</span>
              </div>
            </div>
          </div>
        </div>
      </Screen>
    );
  }
}

ManagePictures.propTypes = {
  t: PropTypes.func.isRequired
};

export default withRouter(withTranslation('common')(ManagePictures));
