import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { handleChange } from 'redux/actions';
import { connect } from 'react-redux';
import FormContext from 'context/FormContext';
import Select from 'react-select';
import { objt } from 'utils';

export class DropdownSingle extends Component {
  constructor (props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  getRequired () {
    if (this.props.required === true) {
      return <span className="tw-font-semibold tw-text-red-500 tw-text-sm tw-ml-2">{this.props.t('required')}</span>;
    }
  }

  handleChange (data, context) {
    if (data) {
      this.props.handleChange(this.props.name, data.value, context.name);
    } else {
      this.props.handleChange(this.props.name, undefined, context.name);
    }
  }

  getOptions () {
    var options = this.props.options ? this.props.options.map((option) => {
      return {
        value: option.id,
        label: objt(option)

      };
    }) : {};
    return options;
  }

  getValue (context) {
    if (this.props.input && this.props.input[context.name] && this.props.input[context.name][this.props.name]) {
      return this.getOptions().filter(option => option.value === this.props.input[context.name][this.props.name]);
    } else {
      return undefined;
    }
  }

  render () {
    return (
      <FormContext.Consumer>
        {context =>
          <div className={`tw-flex tw-flex-col ${this.props.size} tw-px-2 tw-mb-3`}>
            <label htmlFor={this.props.name} className="tw-text-sm tw-font-bold">{this.props.title || this.props.t('common:' + this.props.name)}{this.getRequired()}</label>
            {this.props.subtext && <p className="tw-text-sm tw-text-gray-700">{this.props.subtext}</p>}
            <Select options={this.getOptions()}
              isClearable={true}
              value={this.getValue(context)}
              name={this.props.name}
              onChange={(data) => {
                this.handleChange(data, context);
              }}
              styles={{
                control: (provided, state) => ({
                  ...provided,
                  backgroundColor: '#e2e8f0'
                }),
                menu: (provided, state) => ({
                  ...provided,
                  zIndex: '9999',
                  color: 'black'
                })
              }}
              classNamePrefix="select" />
            {this.props.errors && this.props.errors[context.name] && this.props.errors[context.name][this.props.name] && (
              <div className="tw-bg-red-100 tw-mt-2 tw-border-l-4 tw-border-red-500 tw-text-red-700 tw-p-2 tw-text-sm">
                <p>{this.props.errors[context.name][this.props.name]}</p>
              </div>
            )}

          </div>
        }
      </FormContext.Consumer>

    );
  }
}

DropdownSingle.defaultProps = {
  size: 'w-full',
  required: true,
  type: 'text'
};

DropdownSingle.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  size: PropTypes.string.isRequired,
  required: PropTypes.bool,
  type: PropTypes.string,
  t: PropTypes.func.isRequired,
  subtext: PropTypes.string
};

const mapStateToProps = ({ errors, input }, ownProps) => {
  return {
    errors: errors,
    input: input
  };
};

export default connect(mapStateToProps, { handleChange })(withTranslation(['input'])(DropdownSingle));
