import React, { Component } from 'react';
import close from 'img/icons/close.svg';
import bell from 'img/icons/bell.svg';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

export class Notifications extends Component {
  displaySnippet (text) {
    if (text.length > 30) {
      return text.substr(0, 30) + '...';
    } else {
      return text;
    }
  }

  render () {
    return this.props.opened === true
      ? (
        <div onClick={(e) => {
          if (e.target === e.currentTarget) {
            this.props.handle(false);
          }
        }} style={{ backgroundColor: 'rgba(0, 0, 0, 0.65)', position: 'absolute', zIndex: 100 }} className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-inset-0">
          <img src={close} height="25" width="25" style={{ cursor: 'pointer', position: 'absolute', top: 20, right: 20 }} onClick={() => { this.props.handle(false); }}/>
          <div style={{ minHeight: '80vh', maxHeight: '80vh', overflowY: 'auto' }} className="col-lg-6 tw-mx-auto tw-pb-2">
            <div className="tw-card" style={{ background: '#434349' }}>
              <h2 className="tw-text-white tw-text-lg tw-font-bold">Notifications</h2>
              {this.props.posts.map(p => (
                <div key={p.post.id} className="tw-py-2 hover:tw-bg-black tw-cursor-pointer tw-flex tw-flex-row tw-items-center tw-space-x-2" onClick={() => {
                  this.props.handle(false);
                  setTimeout(this.props.updateNotifs, 10000);
                  this.props.history.push('/posts/' + p.post.id);
                }}>
                  <img className="tw-m-2" src={bell} style={{ minHeight: 30, minWidth: 30 }} />
                  <p>
                    {p.commenters.length === 1 && this.props.t('notifSingleText', { name: p.commenters[0] })}
                    {p.commenters.length === 2 && this.props.t('notifDoubleText', { name: p.commenters[0], name2: p.commenters[1] })}
                    {p.commenters.length > 2 && this.props.t('notifText', { name: p.commenters[0], number: p.commenters.length - 1 })}
                    {this.displaySnippet(p.post.text)}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : null;
  }
}

export default withTranslation('common')(withRouter(Notifications));
