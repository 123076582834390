import React from 'react';
import { Helmet } from 'react-helmet';
import SendApplication from 'pages/forms/SendApplication';
import { withTranslation } from 'react-i18next';
import MyProfile from 'pages/profile/MyProfile';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Login from 'pages/forms/Login';
import Settings from 'pages/misc/Settings';
import Screen from 'components/templates/Screen';
import VerifyEmail from 'pages/profile/VerifyEmail';
import EmailVerified from 'pages/profile/EmailVerified';
import ModifyProfile from 'pages/profile/ModifyProfile';
import PrivateRoute from './PrivateRoute';
import Terms from 'pages/misc/Terms';
import Logout from 'pages/misc/Logout';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import NewEmailNotification from './pages/newEmail/NewEmailNotification';
import NewEmailVerified from './pages/newEmail/NewEmailVerified';
import ComingSoon from './pages/misc/ComingSoon';
import JobOffers from 'pages/jobOffers/JobOffers';
import PropTypes from 'prop-types';
import Homepage from './pages/Homepage';
import LogoutPage from 'pages/misc/LogoutPage';
import JobOffer from 'pages/jobOffers/JobOffer';
import ProductPage from 'pages/shop/ProductPage';
import Shop from 'pages/shop/Shop';
import Cart from 'pages/shop/Cart';
import OrderSuccess from 'pages/shop/OrderSuccess';
import Policies from 'pages/shop/Policies';
import EmployerShop from './pages/shop/EmployerShop';
import ManagePictures from 'pages/profile/ManagePictures';
import ManageNetwork from 'pages/profile/ManageNetwork';
import PublicProfile from 'pages/profile/PublicProfile';
import Messaging from 'pages/profile/Messaging';
import CoffeeMachine from 'pages/coffeeMachine/CoffeeMachine';
import Post from 'pages/coffeeMachine/Post';
import CreatePost from 'pages/coffeeMachine/CreatePost';
import UpdatePost from 'pages/coffeeMachine/UpdatePost';
import Dashboard from 'pages/profile/Dashboard';
import SignupLoginPrompt from 'components/common/SignupLoginPrompt';
import GoogleAnalytics from './GoogleAnalytics';
import Welcome from 'pages/Welcome';
import CoffeeMachineRoute from 'CoffeeMachineRoute';
import * as Cookies from 'js-cookie';

function App (props) {
  var parser = new URLSearchParams(window.location.search)
  if(parser.get('invite') && Cookies.get('invite') === undefined) {
    Cookies.set('invite', parser.get('invite'), { expires: 30 });
  }
  return (
    <div className="tw-font-display">
      <Helmet>
        <title>Entreprises d'ICI</title>
      </Helmet>
      <ReactNotification />
      <Router>
        <GoogleAnalytics />
        <SignupLoginPrompt />
        <Switch>
          <Route path="/screen">
            <Screen title="Test Screen" />
          </Route>
          <Route path="/logout" component={Logout} />
          <Route path="/settings" component={Settings} />
          <Route path="/terms" component={Terms} />
          <Route path="/verify-new-email" component={NewEmailVerified} />
          <Route path="/new-email" component={NewEmailNotification} />
          <Route path="/logout-page" component={LogoutPage} />
          <PrivateRoute email={false} path="/profile/network" component={ManageNetwork} />
          <Route path="/profile/:id" component={PublicProfile} />
          <PrivateRoute email={false} path="/dashboard" component={Dashboard} />
          <PrivateRoute email={false} path="/profile" component={MyProfile} />
          <PrivateRoute email={false} path="/modify-profile" component={ModifyProfile} />
          <PrivateRoute reverse={true} path={['/signup', '/candidate-signup']} component={SendApplication} />
          <PrivateRoute path="/verify-email" component={VerifyEmail} />
          <Route path="/email-verified" component={EmailVerified} />
          <PrivateRoute reverse={true} path="/login" component={Login} />
          <Route path="/coffee-machine" component={CoffeeMachine} />
          <Route path="/posts/create" component={CreatePost} />
          <Route path="/posts/:id/update" component={UpdatePost} />
          <Route path="/messaging" component={Messaging} />
          <Route component={JobOffer} path="/job-offer/:id" />
          <Route component={JobOffer} path="/job-offre/:id" />
          <Route component={EmployerShop} path="/shop/:id" />
          <Route component={Shop} path="/shop" />
          <Route component={Cart} path="/cart" />
          <Route component={ProductPage} path="/products/:id" />
          <Route component={Policies} path="/policy/:id" />
          <Route component={OrderSuccess} path="/order/success" />
          <PrivateRoute email={true} path="/job-offers" component={JobOffers} />
          <Route path="/companies" render={() => {
            window.location.href = 'https://employers.alpeconception.com/employers';
            return null;
          }} />
          <Route path="/welcome" component={Welcome} />
          <CoffeeMachineRoute />
        </Switch>
      </Router>
    </div>
  );
}

App.propTypes = {
  i18n: PropTypes.object.isRequired
};

export default withTranslation(['pages', 'common'])(App);
