import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { objt } from 'utils';
import Button from 'components/common/Button';
import Screen from 'components/templates/Screen.js';
import PropTypes from 'prop-types';
import instance from 'axiosInstance';
import { withRouter } from 'react-router-dom';
import profile from 'img/placeholder/profile.svg';
import { Gateway } from 'components/profile/Gateway';
import MediaQuery from 'react-responsive';
export class Dashboard extends Component {
  constructor (props) {
    super(props);
    this.state = { candidate: {}, loading: true };
  }

  componentDidMount () {
    instance.get('/candidate/details').then((s) => {
      this.setState({ candidate: s.data.candidate, loading: false });
    });
  }

  getProfilePic () {
    const { candidate } = this.state;
    if (!candidate.profile_pic) {
      return profile;
    }
    return (process.env.REACT_APP_STORAGE_URL + candidate.profile_pic).replace(/([^:]\/)\/+/g, '$1');
  }

  render () {
    const { t } = this.props;
    const { candidate } = this.state;
    return (
      <Screen type="gray" loading={this.state.loading} title={this.props.t('pages:dashboard')}>
        <div className="tw-w-full tw-flex tw-flex-col">
          <MediaQuery minWidth={768}>
            <div className="tw-w-full tw-flex md:tw-flex-row tw-flex-col tw-text-center" style={{ height: 600 }}>
              <div className="tw-w-full md:tw-w-1/2 tw-flex" style={{ height: 600 }}>
                <div className="tw-w-1/2 tw-h-full tw-p-2">
                  <Gateway text={this.props.t('pages:profile')} img="profile" action={() => { this.props.history.push('/profile'); }} />
                </div>
                <div className="tw-w-1/2 tw-h-full">
                  <div style={{ height: '50%' }} className="tw-w-full tw-p-2">
                    <Gateway text={this.props.t('manageNetwork')} img="network" action={() => { this.props.history.push('/profile/network'); }} />
                  </div>
                  <div style={{ height: '50%' }} className="tw-w-full tw-p-2">
                    <Gateway text={this.props.t('pages:managePosts')} img="coffee" action={() => { this.props.history.push('/profile/' + candidate.id); }} />
                  </div>
                </div>
              </div>
              <div className="tw-w-full md:tw-w-1/2 tw-flex tw-flex-col" style={{ height: 600 }}>
                <div style={{ height: '50%' }} className="tw-w-full tw-p-2">
                  <Gateway text={this.props.t('pages:jobs')} img="bureaux" action={() => { this.props.history.push('/job-offers'); }} />

                </div>
                <div style={{ height: '50%' }} className="tw-h-1/2 tw-flex">
                  <div className="tw-w-1/2 tw-p-2">
                    <Gateway text={this.props.t('pages:shop')} img="shop" action={() => { this.props.history.push('/shop'); }} />
                  </div>
                  <div className="tw-w-1/2 tw-p-2">
                    <Gateway text={this.props.t('business')} img="business" action={() => { window.location.href = 'https://employers.alpeconception.com/employers'; }} />
                  </div>
                </div>
              </div>
            </div>
          </MediaQuery>
          <MediaQuery maxWidth={767}>
            <div className="tw-w-full tw-flex md:tw-flex-row tw-flex-col tw-text-center" style={{ height: 1200 }}>
              <div className="tw-w-full md:tw-w-1/2 tw-flex" style={{ height: 600 }}>
                <div className="tw-w-1/2 tw-h-full tw-p-2">
                  <Gateway text={this.props.t('pages:profile')} img="profile" action={() => { this.props.history.push('/profile'); }} />
                </div>
                <div className="tw-w-1/2 tw-h-full">
                  <div style={{ height: '50%' }} className="tw-w-full tw-p-2">
                    <Gateway text={this.props.t('manageNetwork')} img="network" action={() => { this.props.history.push('/profile/network'); }} />
                  </div>
                  <div style={{ height: '50%' }} className="tw-w-full tw-p-2">
                    <Gateway text={this.props.t('pages:managePosts')} img="coffee" action={() => { this.props.history.push('/profile/' + candidate.id); }} />
                  </div>
                </div>
              </div>
              <div className="tw-w-full md:tw-w-1/2 tw-flex tw-flex-col" style={{ height: 600 }}>
                <div style={{ height: '50%' }} className="tw-w-full tw-p-2">
                  <Gateway text={this.props.t('pages:jobs')} img="bureaux" action={() => { this.props.history.push('/job-offers'); }} />
                </div>
                <div style={{ height: '50%' }} className="tw-h-1/2 tw-flex">
                  <div className="tw-w-1/2 tw-p-2">
                    <Gateway text={this.props.t('pages:shop')} img="shop" action={() => { this.props.history.push('/shop'); }} />
                  </div>
                  <div className="tw-w-1/2 tw-p-2">
                    <Gateway text={this.props.t('business')} img="business" action={() => { window.location.href = 'https://employers.alpeconception.com/employers'; }} />
                  </div>
                </div>
              </div>
            </div>

          </MediaQuery>

          <div className="tw-w-full tw-flex tw-flex-col md:tw-flex-row tw-text-center" style={{ height: 300 }}>
            <div className="tw-w-full tw-h-full tw-flex">
              <div className="tw-w-full md:tw-w-1/2 tw-p-2">
                <Gateway text={this.props.t('feed')} img="coffee" action={() => { this.props.history.push('/'); }} />
              </div>
              <div className="tw-w-full md:tw-w-1/2 tw-p-2">
                <Gateway text={this.props.t('pages:settings')} img="settings" action={() => { this.props.history.push('/settings'); }} />
              </div>
            </div>
          </div>
        </div>
      </Screen>
    );
  }
}

Dashboard.propTypes = {
  t: PropTypes.func.isRequired
};

export default withRouter(withTranslation('common')(Dashboard));
