import axios from 'axios';
import * as Cookies from 'js-cookie';
import { getLanguage } from 'utils';
import { store } from 'react-notifications-component';
import i18n from 'i18n';

var headers = {
  Accept: 'application/json',
  'Content-Type': 'multipart/form-data',
  enctype: 'multipart/form-data'
};

axios.defaults.headers = headers;

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL
});

var params = {};

instance.interceptors.request.use(config => {
  config.params = {
    ...params,
    ...config.params
  };
  var headers = {};

  if (Cookies.get('auth')) {
    headers.Authorization = 'Bearer ' + Cookies.get('auth');
  }

  config.headers = {
    ...config.headers,
    ...headers,
    'Accept-Language': getLanguage()
  };
  return config;
});

instance.interceptors.response.use(res => {
  if (res.data.action) {
    switch (res.data.action) {
      case 'new-email':
        window.location.href = '/new-email?email=' + res.data.email;
        break;
    }
  }
  return res;
}, error => {
  if (error.response.status === 422) {
    store.addNotification({
      title: 'Oops!',
      message: i18n.t('common:fixErrors'),
      type: 'danger',
      insert: 'top',
      container: 'top-right',
      animationIn: ['animate__animated', 'animate__fadeIn'],
      animationOut: ['animate__animated', 'animate__fadeOut'],
      dismiss: {
        duration: 5000,
        onScreen: true
      }
    });
  }
  if (error.response.data.message === 'Unauthenticated.') {
    if (!Cookies.get('auth')) {
      Cookies.remove('email_verified');
    } else {
      Cookies.remove('auth');
      Cookies.remove('email_verified');
      window.location.href = '/login';
    }
  }
  if (error.response.data.action) {
    switch (error.response.data.action) {
      case 'login':
        Cookies.set('origin', window.location.pathname, { expires: 1 / 48 });
        Cookies.remove('auth');
        Cookies.remove('email_verified');
        window.location.href = '/login';
        break;
      case 'verify-email':
        Cookies.set('origin', window.location.pathname, { expires: 1 / 48 });
        Cookies.set('email_verified', false);
        window.location.href = '/verify-email';
        break;
      case 'complete-profile':
        Cookies.set('origin', window.location.pathname, { expires: 1 / 48 });
        window.location.href = '/modify-profile';
        break;
    }
  }
  return Promise.reject(error);
});

export default instance;
