import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { listDescAdd, listDescEdit, listRemove } from 'redux/actions';
import { connect } from 'react-redux';
import FormContext from 'context/FormContext';
import plus from 'img/icons/plus.svg';
import minus from 'img/icons/minus.svg';
import Select from 'react-select';
import { objt } from 'utils';

export class ListInputDescriptionItem extends Component {
  constructor (props) {
    super(props);
    this.state = { description: '', training: '' };
    this.handleChangeTraining = this.handleChangeTraining.bind(this);
    this.handleChangeDescription = this.handleChangeDescription.bind(this);
    this.handleEditDescription = this.handleEditDescription.bind(this);
    this.handleEditTraining = this.handleEditTraining.bind(this);
    this.handleAdding = this.handleAdding.bind(this);
  }

  getRequired () {
    if (this.props.required === true) {
      return <span className="tw-font-semibold tw-text-red-500 tw-text-sm tw-ml-2">{this.props.t('required')}</span>;
    }
  }

  // handle input text change
  handleChangeTraining (data) {
    if (data) {
      this.setState({ training: data.value });
    } else {
      this.setState({ training: undefined });
    }
  }

  handleChangeDescription (e) {
    var { value } = e.target;
    this.setState({ description: value });
  }

  // handle sending new entry
  // clear state when sending
  handleAdding (context) {
    if (this.state.description && this.state.training) {
      this.props.listDescAdd(this.props.name, this.state.description, this.state.training, context.name);
      this.setState({ description: '', training: undefined });
    }
  }

  // handle sending on enter
  // use state and handleAdding
  _handleKeyDown (e, context) {
    if (e.key === 'Enter' && this.state.description && this.state.training) {
      this.props.listDescAdd(this.props.name, this.state.description, this.state.training, context.name);
      this.setState({ description: '', training: undefined });
    }
  }

  // handle edit of existing value
  handleEditDescription (e, context) {
    this.props.listDescEdit(this.props.name, e.target.value, this.getValue(context), context.name, this.props.item.id);
  }

  handleEditTraining (data, context) {
    this.props.listDescEdit(this.props.name, this.getValueDescription(context), ((data && data.value) || null), context.name, this.props.item.id);
  }

  getValue (context = null) {
    if (context === null) {
      if (this.state.training) {
        return this.getOptions().filter(option => option.value === this.state.training)[0];
      } else {
        return null;
      }
    }
    if (this.props.input && this.props.input[context.name] && this.props.input[context.name][this.props.name] && this.props.input[context.name][this.props.name].filter(i => i.id === this.props.item.id).length !== 0) {
      return this.getOptions().filter(option => {
        return option.value === this.props.input[context.name][this.props.name].filter(i => i.id === this.props.item.id)[0].training;
      })[0];
    } else {
      return null;
    }
  }

  getValueDescription (context) {
    if (this.props.input && this.props.input[context.name] && this.props.input[context.name][this.props.name] && this.props.input[context.name][this.props.name] && this.props.input[context.name][this.props.name].filter(i => i.id === this.props.item.id).length !== 0) {
      return this.props.input[context.name][this.props.name].filter(i => i.id === this.props.item.id)[0].value;
    } else {
      return undefined;
    }
  }

  getOptions () {
    var options = this.props.options ? this.props.options.map((option) => {
      return {
        value: option.id,
        label: objt(option)

      };
    }) : {};
    return options;
  }

  renderInput (context) {
    if (this.props.type === 'add') {
      return (
        <div className="tw-flex tw-flex-row tw-w-full">
          <div className="tw-flex tw-flex-col tw-flex-1">
            <input
              type={this.props.type}
              id={this.props.name}
              placeholder="Description"
              className="focus:tw-outline-none focus:tw-shadow-outline tw-bg-gray-300   tw-py-2 tw-px-3 tw-flex-1"
              style={{ height: '38px' }}
              value={this.state.description}
              onChange={this.handleChangeDescription}
              onKeyDown={(e) => {
                this._handleKeyDown(e, context);
              }} />
            <Select
              options={this.getOptions()}
              isClearable={true}
              value={this.getValue()}
              name={this.props.name}
              onChange={(data) => {
                this.handleChangeTraining(data);
              }}
              styles={{
                control: (provided, state) => ({
                  ...provided,
                  backgroundColor: '#e2e8f0'
                }),
                menu: (provided, state) => ({
                  ...provided,
                  zIndex: '9999'
                })
              }}
              classNamePrefix="select" />
          </div>
          <div onClick={(e) => {
            this.handleAdding(context);
          }} className="tw-bg-green-500  tw-cursor-pointer tw-flex tw-flex-row tw-justify-center tw-items-center tw-h-full" style={{ width: '38px' }}>
            <img src={plus} className="w-1/2" />
          </div>
        </div>
      );
    } else {
      return (
        <div className="tw-flex tw-flex-row tw-w-full">
          <div className="tw-flex tw-flex-col tw-flex-1">
            <input
              type={this.props.type}
              id={this.props.name}
              placeholder="Description"
              className="tw-text-black focus:tw-outline-none focus:tw-shadow-outline tw-bg-gray-300   tw-py-2 tw-px-3 tw-flex-1"
              style={{ height: '38px' }}
              value={this.getValueDescription(context)}
              onChange={(e) => { this.handleEditDescription(e, context); }}
            />
            <Select
              options={this.getOptions()}
              isClearable={true}
              value={this.getValue(context)}
              name={this.props.name}
              onChange={(data) => {
                this.handleEditTraining(data, context);
              }}
              styles={{
                control: (provided, state) => ({
                  ...provided,
                  backgroundColor: '#e2e8f0'
                }),
                menu: (provided, state) => ({
                  ...provided,
                  zIndex: '9998',
                  color: 'black'
                }),
                menuList: (provided, state) => ({
                  ...provided,
                  zIndex: '9999'
                })
              }}
              classNamePrefix="select" />
          </div>
          <div onClick={(e) => {
            this.props.listRemove(this.props.name, context.name, this.props.item.id);
          }} className="tw-bg-red-500  tw-cursor-pointer tw-flex tw-flex-row tw-justify-center tw-items-center tw-h-full" style={{ width: '38px' }}>
            <img src={minus} className="w-1/2" />
          </div>
        </div>
      );
    }
  }

  render () {
    return (
      <FormContext.Consumer>
        {context =>
          <div className={`tw-flex tw-flex-col ${this.props.size} tw-px-2 tw-mb-3`}>
            <div className="tw-flex tw-flex-row tw-w-full">
              {this.renderInput(context)}
            </div>
          </div>
        }
      </FormContext.Consumer>

    );
  }
}

ListInputDescriptionItem.defaultProps = {
  size: 'w-full',
  required: true,
  type: 'text'
};

ListInputDescriptionItem.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  size: PropTypes.string.isRequired,
  required: PropTypes.bool,
  type: PropTypes.string,
  t: PropTypes.func.isRequired
};

const mapStateToProps = ({ errors, input }, ownProps) => {
  return {
    errors: errors,
    input: input
  };
};

export default connect(mapStateToProps, { listDescAdd, listDescEdit, listRemove })(withTranslation(['input'])(ListInputDescriptionItem));
