import { HANDLE_CHANGE, CLEAR_FORM, LIST_ADD, LIST_EDIT, LIST_REMOVE, LIST_DESC_ADD, LIST_DESC_EDIT } from '../actionTypes';
import produce from 'immer';

const input = (state = { listId: 0 }, action) => {
  switch (action.type) {
    case HANDLE_CHANGE: {
      const { name, value, form } = action.payload;
      if (!state[form]) {
        return produce(state, draft => {
          draft[form] = {};
          draft[form][name] = value;
        });
      } else {
        return produce(state, draft => {
          draft[form][name] = value;
        });
      }
    }
    case LIST_ADD: {
      const { name, value, form } = action.payload;
      if (!state[form]) {
        return produce(state, draft => {
          draft[form] = {};
          draft[form][name] = [{ id: draft.listId, value: value }];
          draft.listId++;
        });
      } else {
        return produce(state, draft => {
          if (!draft[form][name]) {
            draft[form][name] = [];
          }
          draft[form][name].unshift({ id: draft.listId, value: value });
          draft.listId++;
        });
      }
    }
    case LIST_EDIT: {
      const { name, value, id, form } = action.payload;
      return produce(state, draft => {
        draft[form][name] = draft[form][name].map(item => {
          if (item.id === id) {
            item.value = value;
          }
          return item;
        });
      });
    }
    case LIST_REMOVE: {
      const { name, id, form } = action.payload;
      return produce(state, draft => {
        draft[form][name] = draft[form][name].filter(item => item.id !== id);
      });
    }
    case LIST_DESC_ADD: {
      const { name, value, training, form } = action.payload;
      if (!state[form]) {
        return produce(state, draft => {
          draft[form] = {};
          draft[form][name] = [{ id: draft.listId, training: training, value: value }];
          draft.listId++;
        });
      } else {
        return produce(state, draft => {
          if (!draft[form][name]) {
            draft[form][name] = [];
          }
          draft[form][name].unshift({ id: draft.listId, training: training, value: value });
          draft.listId++;
        });
      }
    }
    case LIST_DESC_EDIT: {
      const { name, value, training, id, form } = action.payload;
      return produce(state, draft => {
        draft[form][name] = draft[form][name].map(item => {
          if (item.id === id) {
            item.value = value;
            item.training = training;
          }
          return item;
        });
      });
    }
    case CLEAR_FORM: {
      const { form } = action.payload;
      return produce(state, draft => {
        delete draft[form];
      });
    }
    default:
      return state;
  }
};

export default input;
