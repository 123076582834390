import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FormTemplate from 'components/templates/FormTemplate';
import TextInput from 'components/forms/TextInput';
import Form from 'components/forms/Form';
import FormGroup from 'components/forms/FormGroup';
import FileInput from 'components/forms/FileInput';
import YesNoInput from 'components/forms/YesNoInput';
import { withTranslation } from 'react-i18next';
import Submit from 'components/forms/Submit';
import DropdownMultiple from 'components/forms/DropdownMultiple';
import { connect } from 'react-redux';
import instance from 'axiosInstance';
import { addErrors } from 'redux/actions';
import DropdownSingle from 'components/forms/DropdownSingle';
import { formToFormData } from 'utils';
import * as Cookies from 'js-cookie';
import ListInput from 'components/forms/ListInput';
import ListInputDescription from './../../components/forms/ListInputDescription';
import SecondaryButton from 'components/common/SecondaryButton';
import { withRouter } from 'react-router-dom';
import Button from 'components/common/Button';

export class SendApplication extends Component {
  constructor (props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = { interests: [], fieldsOfWork: [], salary: [], trainings: [], loading: false, stage: 1 };
  }

  handleSubmit (e) {
    e.preventDefault();
    this.setState({ loading: true });
    var fd = formToFormData(['email', 'email_confirmation', 'password', 'password_confirmation', 'first_name', 'last_name', 'phone_number', 'postal', 'city', 'field_of_work', 'interests', 'salary_id', 'availability', 'job_type', 'work_experiences', 'cv', 'video_cv', 'training', 'bio', 'driver', 'car', 'work_permit', 'student_permit', 'permanence', 'citizen'], this.props.form);
    fd.append('invite', Cookies.get('invite'));
    instance.post('/candidate' + (this.props.location.search === '?type=buyer' ? '?candidate=false' : ''), fd).then((success) => {
      Cookies.set('auth', success.data.token);
      Cookies.set('email_verified', success.data.email_verified);
      this.props.history.push('/verify-email');
      if (Cookies.get('job_offer')) {
        instance.get('/job-offer-without-email?job_offer=' + Cookies.get('job_offer'));
        Cookies.remove('job_offer');
      }
    }, (error) => {
      this.checkErrorsStage(error.response.data.errors);
      this.props.addErrors(error.response.data.errors, 'signup');
    }).then(() => {
      this.setState({ loading: false });
    });
  }

  checkErrorsStage (errors) {
    var stage1 = false;
    var stage2 = false;
    var stage3 = false;
    if (typeof errors === 'object') {
      Object.keys(errors).forEach(error => {
        if (['first_name', 'last_name', 'phone_number', 'postal', 'city', 'bio'].includes(error)) {
          stage1 = true;
        }
        if (['field_of_work', 'interests', 'salary_id', 'availability', 'job_type', 'work_experiences', 'cv', 'video_cv', 'training'].includes(error)) {
          stage2 = true;
        }
        if (['password', 'password_confirmation', 'email', 'email_confirmation'].includes(error)) {
          stage3 = true;
        }
      });
      if (stage1 === true) {
        this.setState({ stage: 1 });
      } else if (stage2 === true) {
        this.setState({ stage: 2 });
      } else if (stage3 === true) {
        this.setState({ state: 3 });
      }
    }
  }

  componentDidMount () {
    instance.get('/candidate-data')
      .then((success) => {
        this.setState({
          loading: false,
          interests: success.data.interests,
          fieldsOfWork: success.data.fieldsOfWork,
          salary: success.data.salary,
          availability: success.data.availability,
          job_type: success.data.job_type,
          trainings: success.data.trainings
        });
      });
  }

  getStage () {
    const { stage } = this.state;
    if (stage === 1) {
      return (<Form submitOnEnter={false} key={this.state.stage} onSubmit={(e) => {
        e.preventDefault();
        this.setState({ stage: 2 });
      }} name="signup">
        <FormGroup>
          <TextInput name="first_name" />
          <TextInput name="last_name" />
        </FormGroup>
        <TextInput name="email" type="email" />
        <TextInput name="phone_number" />
        <TextInput required="false" name="postal" />
        <TextInput required="false" name="city" />
        <TextInput name="bio" required="false" />
        <Submit />
      </Form>);
    }
    if (stage === 2) {
      return (<Form key={this.state.stage} submitOnEnter={false} key={this.state.stage} onSubmit={(e) => {
        e.preventDefault();
        this.setState({ stage: 3 });
      }} name="signup">
        <DropdownMultiple required="false" name="field_of_work" options={this.state.fieldsOfWork} />
        <DropdownMultiple name="interests" options={this.state.interests}required="false"/>
        <DropdownSingle name="salary_id" options={this.state.salary} required="false"/>
        <DropdownMultiple required="false" name="availability" options={this.state.availability} />
        <DropdownMultiple required="false" name="job_type" options={this.state.job_type} />
        <ListInputDescription name="training" options={this.state.trainings} />
        <ListInput name="work_experiences" />
        <YesNoInput name="driver" />
        <YesNoInput name="car" />
        <YesNoInput name="student_permit" />
        <YesNoInput name="work_permit" />
        <YesNoInput name="permanence" />
        <YesNoInput name="citizen" />
        <FileInput name="cv" subtitle={this.props.t('uploadCVFormats')} accepted="pdf,docx" required={false} />
        <FileInput name="video_cv" subtitle={this.props.t('uploadVideoCVFormat')} accepted="avi,mp4" required={false} />
        <div className="tw-flex tw-flex-row tw-justify-between tw-items-center">
          <SecondaryButton
            action={() => { this.setState({ stage: 1 }); }}
            text={this.props.t('goBack')} />
          <Submit />
        </div>
      </Form>);
    }
    if (stage === 3) {
      return (<Form key={this.state.stage} onSubmit={this.handleSubmit} name="signup">
        <TextInput name="email" type="email" />
        <TextInput name="email_confirmation" type="email" />
        <TextInput name="password" type="password" />
        <TextInput name="password_confirmation" type="password" />
        <div className="tw-flex tw-flex-row tw-justify-between tw-items-center">
          <SecondaryButton
            action={() => { this.setState({ stage: 2 }); }}
            text={this.props.t('goBack')} />
          <Submit />
        </div>
      </Form>);
    }
  }

  render () {
    return (
      <FormTemplate loading={this.state.loading} title={this.props.t('pages:sendApplication')}>
        {this.getStage()}
      </FormTemplate>
    );
  }
}

SendApplication.propTypes = {
  t: PropTypes.func.isRequired
};

SendApplication.defaultProps = {
  form: {}
};

const mapStateToProps = ({ input }) => {
  return {
    form: input.signup
  };
};

export default withRouter(connect(mapStateToProps, { addErrors })(withTranslation('common')(SendApplication)));
