import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { listAdd, listEdit, listRemove } from 'redux/actions';
import { connect } from 'react-redux';
import FormContext from 'context/FormContext';
import plus from 'img/icons/plus.svg';
import minus from 'img/icons/minus.svg';

export class ListInputItem extends Component {
  constructor (props) {
    super(props);
    this.state = { value: '' };
    this.handleChange = this.handleChange.bind(this);
    this.handleAdding = this.handleAdding.bind(this);
  }

  getRequired () {
    if (this.props.required === true) {
      return <span className="tw-text-black tw-font-semibold tw-text-red-500 tw-text-sm tw-ml-2">{this.props.t('required')}</span>;
    }
  }

  // handle input text change
  handleChange (e) {
    var { value } = e.target;
    this.setState({ value: value });
  }

  // handle sending new entry
  // clear state when sending
  handleAdding (context) {
    if (this.state.value !== '') {
      this.props.listAdd(this.props.name, this.state.value, context.name);
      this.setState({ value: '' });
    }
  }

  // handle sending on enter
  // use state and handleAdding
  _handleKeyDown (e, context) {
    if (e.key === 'Enter' && this.state.value) {
      this.props.listAdd(this.props.name, this.state.value, context.name);
      this.setState({ value: '' });
    }
  }

  // handle edit of existing value
  handleEdit (e, context, id) {
    var value = e.target.value;
    this.props.listEdit(this.props.name, value, context.name, id);
  }

  getValue (context) {
    if (this.props.input && this.props.input[context.name] && this.props.input[context.name][this.props.name]) {
      return this.props.input[context.name][this.props.name];
    } else {
      return undefined;
    }
  }

  renderInput (context) {
    if (this.props.type === 'add') {
      return (
        <>
          <input
            type={this.props.type}
            id={this.props.name}
            placeholder={this.props.title}
            className="focus:tw-outline-none focus:tw-shadow-outline tw-bg-gray-300  tw-py-2 tw-px-3 tw-flex-1"
            style={{ height: '38px' }}
            value={this.state.value}
            onChange={this.handleChange}
            onKeyDown={(e) => {
              this._handleKeyDown(e, context);
            }} />
          <div onClick={(e) => {
            this.handleAdding(context);
          }} className="tw-bg-green-500  tw-cursor-pointer tw-flex tw-flex-row tw-justify-center tw-items-center" style={{ height: '38px', width: '38px' }}>
            <img src={plus} className="w-1/2" />
          </div>
        </>
      );
    } else {
      return (
        <>
          <input
            onChange={(e) => {
              this.handleEdit(e, context, this.props.item.id);
            }}
            value={this.props.item.value}
            placeholder={this.props.title}
            className="focus:tw-outline-none focus:tw-shadow-outline tw-bg-gray-300  tw-py-2 tw-px-3 tw-flex-1"
            style={{ height: '38px' }} />
          <div className="tw-bg-red-500  tw-cursor-pointer tw-flex tw-flex-row tw-justify-center tw-items-center" style={{ height: '38px', width: '38px' }} onClick={() => {
            this.props.listRemove(this.props.name, context.name, this.props.item.id);
          }}>            <img src={minus} className="w-1/2" />
          </div>
        </>
      );
    }
  }

  render () {
    return (
      <FormContext.Consumer>
        {context =>
          <div className={`tw-flex tw-flex-col ${this.props.size} tw-px-2 tw-mb-3`}>
            <div className="tw-flex tw-flex-row tw-w-full">
              {this.renderInput(context)}
            </div>
          </div>
        }
      </FormContext.Consumer>

    );
  }
}

ListInputItem.defaultProps = {
  size: 'w-full',
  required: true,
  type: 'text'
};

ListInputItem.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  size: PropTypes.string.isRequired,
  required: PropTypes.bool,
  type: PropTypes.string,
  t: PropTypes.func.isRequired
};

const mapStateToProps = ({ errors, input }, ownProps) => {
  return {
    errors: errors,
    input: input
  };
};

export default connect(mapStateToProps, { listAdd, listEdit, listRemove })(withTranslation(['input'])(ListInputItem));
