import { connect } from 'react-redux';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import close from 'img/icons/close.svg';
import { closeOverlay, isAuth } from 'utils';

export class SignupLoginPrompt extends Component {
  render () {
    if (!isAuth() && (this.props.forceOpen === true || (this.props.overlay === true && !this.props.location.pathname.startsWith('/job-offer/') && !this.props.location.pathname.startsWith('/login') && !this.props.location.pathname.startsWith('/signup') && !this.props.location.pathname.startsWith('/welcome')))) {
      return (
        <div className="tw-absolute tw-inset-0 tw-flex tw-flex-col tw-justify-end tw-text-2xl tw-text-center">
          <div style={{ height: '50vh', zIndex: 999 }} className="tw-space-y-4 bg-434349 tw-flex tw-flex-col tw-justify-center tw-items-center tw-text-white tw-p-16 tw-relative">
            <p clasName="tw-text-2xl">{this.props.t('onboarding')}</p>
            <div onClick={() => { closeOverlay(); this.props.history.push('/login'); }}className="bg-gray tw-p-4 tw-border-b-8 tw-border-aa-yellow tw-cursor-pointer">{this.props.t('login')}</div>
            <div onClick={() => { closeOverlay(); this.props.history.push('/signup'); }}className="tw-mt-2 tw-cursor-pointer">{this.props.t('signup')}</div>
            <img onClick={closeOverlay} src={close} style={{ top: 10, right: 10 }} className="tw-cursor-pointer tw-absolute" />
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = ({ overlay }) => {
  return {
    overlay: overlay.overlay,
    forceOpen: overlay.forceOpen
  };
};

export default connect(mapStateToProps, null)(withTranslation('common')(withRouter(SignupLoginPrompt)));
