import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import heartEmpty from 'img/icons/heart-empty.svg';
import heartFill from 'img/icons/heart-fill.svg';
import comment from 'img/icons/comment.svg';
import share from 'img/icons/share.svg';
import instance from 'axiosInstance';
import ReactLoading from 'react-loading';
import { withTranslation } from 'react-i18next';
import { authOverlay, getQuantityFromCart, addToCart, getAmountInCart } from 'utils';
import { emailSent } from 'img/emailSent.svg';
import GifPlayer from 'react-gif-player';

export class PostComponent extends Component {
  constructor () {
    super();
    this.state = { liked: null, amountLiked: null, likeLoading: false, timeout: null, loaded: false };
    this.onHover = this.onHover.bind(this);
    this.onLeave = this.onLeave.bind(this);
  }

  componentDidMount () {
    this.setState({ liked: this.props.liked, amountLiked: this.props.post.likes_count });
  }

  displaySnippet () {
    const { text } = this.props.post;
    if(this.getMedia() == null) {
      if (text.length > 300) {
        return text.substr(0, 300) + '...';
      } else {
        return text;
      }  
    }
    if (text.length > 30) {
      return text.substr(0, 30) + '...';
    } else {
      return text;
    }
  }

  getYoutubeLink () {
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = this.props.post.video_link.match(regExp);
    return (match && match[7].length === 11) ? match[7] : false;
  }

  onHover(e) {
    e.persist();
    if(this.state.loaded == false) {
      var t = window.setTimeout(function () {
        e.target.click();
        this.setState({loaded: true});
      }.bind(this), 1000); 
      this.setState({timeout: t});
      }
  }

  onLeave(e) {
    e.persist();
    if(this.state.timeout) {
        window.clearTimeout(this.state.timeout);
    }
  }

  getMedia () {
    const { post } = this.props;
    if (post.still) {
      return (
        <div 
        onMouseEnter={(e) => {this.onHover(e)}} 
        onMouseLeave={(e) => {this.onLeave(e)}}>
          <GifPlayer gif={(process.env.REACT_APP_STORAGE_URL + post.thumbnail).replace(/([^:]\/)\/+/g, '$1')} still={(process.env.REACT_APP_STORAGE_URL + post.still).replace(/([^:]\/)\/+/g, '$1')} />
        </div>);
    }

    if (post.thumbnail) {
      return (
        <div>
          <img onClick={() => {
            if (post.link_on_site) {
              window.location.href = post.link_on_site;
            } else {
              if (this.props.profile) {
                this.props.history.push('/profile/' + this.props.profile + '/posts/' + post.id);
              } else {
                this.props.history.push('/posts/' + post.id);
              }
            }
          } } className="tw-cursor-pointer tw-w-full tw-object-cover" src={(process.env.REACT_APP_STORAGE_URL + post.thumbnail).replace(/([^:]\/)\/+/g, '$1')}/>
        </div>);
    }
    if (post.images.length !== 0) {
      return (
        <div>
          <img onClick={() => {
            if (post.link_on_site) {
              window.location.href = post.link_on_site;
            } else {
              if (this.props.profile) {
                this.props.history.push('/profile/' + this.props.profile + '/posts/' + post.id);
              } else {
                this.props.history.push('/posts/' + post.id);
              }
            }
          } } className="tw-cursor-pointer tw-w-full tw-object-cover" src={(process.env.REACT_APP_STORAGE_URL + post.images[0].path).replace(/([^:]\/)\/+/g, '$1')}/>
        </div>);
    }
    if (post.distant_url && post.distant_url.length !== 0) {
      return (
        <div>
          <img onClick={() => {
            if (this.props.profile) {
              this.props.history.push('/profile/' + this.props.profile + '/posts/' + post.id);
            } else {
              this.props.history.push('/posts/' + post.id);
            }
          } } className="tw-cursor-pointer tw-w-full tw-object-cover" src={post.distant_url}/>
        </div>);
    }

    if (post.video_link) {
      return <iframe className="tw-w-full" height="180" c src={'https://www.youtube.com/embed/' + this.getYoutubeLink()} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>;
    }
    return null;
  }

  addLike () {
    const { post } = this.props;
    this.setState(state => {
      var liked = !state.liked;
      var amountLiked = state.liked === true ? parseInt(state.amountLiked) - 1 : parseInt(state.amountLiked) + 1;
      return { liked, amountLiked, likeLoading: true };
    });
    instance.get(`/posts/${post.id}/like`).then(s => { this.setState({ likeLoading: false }); });
  }

  getLiked () {
    if (this.state.liked === null) {
      return this.props.liked === true ? <img src={heartFill} style={{ height: 24, width: 24 }} /> : <img src={heartEmpty} style={{ height: 24, width: 24 }} />;
    }
    return this.state.liked === true ? <img src={heartFill} style={{ height: 24, width: 24 }} /> : <img src={heartEmpty} style={{ height: 24, width: 24 }} />;
  }

  share () {
    if (navigator.share) {
      navigator.share({
        url: 'https://alpeconception.com/posts/' + this.props.post.id
      });
    } else {
      var dummy = document.createElement('textarea');
      document.body.appendChild(dummy);
      dummy.value = 'https://alpeconception.com/posts/' + this.props.post.id;
      dummy.select();
      document.execCommand('copy');
      document.body.removeChild(dummy);
      alert(this.props.t('copiedLink'));
    }
  }

  render () {
    const { post } = this.props;
    if (post.link_on_site != null) {
      return (
        <div className="tw-w-full tw-p-1">
          <div style={{ background: '#434349' }}>
            {this.getMedia()}
            <div className="tw-p-2 tw-px-4 tw-text-white">
              <p className="tw-cursor-pointer tw-break-words" onClick={() => {
                window.location.href = post.link_on_site;
              } } >{post.text}</p>
              {post.posteable_type === 'App\\Product' &&
              <div className="tw-flex-row tw-flex tw-items-center tw-flex-start tw-space-x-2">
                <button className="focus:tw-bg-yellow-700 tw-bg-aa-yellow tw-px-3 tw-py-2 tw-font-bold  tw-text-sm tw-m-1 tw-self-end tw-leading-none tw-min-w-32 tw-text-black tw-cursor-pointer tw-flex tw-flex-row focus:tw-outline-none" onClick={(e) => {
                  addToCart(post.posteable_id);
                  e.target.blur();
                  this.props.cartUpdate(getAmountInCart());
                  this.forceUpdate();
                }}>{this.props.t('addToCart')}</button>
                {getQuantityFromCart(post.posteable_id) > 0 && <p className="tw-text-white">({getQuantityFromCart(post.posteable_id)})</p> }
              </div>}
              <div className="tw-flex tw-flex-row tw-justify-between">
                <div className="tw-flex tw-flex-row tw-space-x-4 tw-py-2">
                  <div className="tw-flex tw-flex-row tw-cursor-pointer" onClick={() => { authOverlay() && this.addLike(); }}>
                    {this.state.likeLoading == true ? <ReactLoading type="spin" color="#ECC94B" height={24} width={24} /> : <>
                      <span className="tw-mr-2">{this.state.amountLiked}</span>
                      {this.getLiked()}
                    </>}
                  </div>
                </div>
                <div className="tw-flex tw-flex-row tw-space-x-4 tw-py-2 tw-cursor-pointer">
                  <img onClick={() => { this.share(); }} src={share} style={{ height: 24, width: 24 }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="tw-w-full tw-p-1">
        <div style={{ background: '#434349' }}>
          {this.getMedia()}
          <div className="tw-p-2 tw-px-4 tw-text-white">
            <p className="tw-cursor-pointer tw-break-words" onClick={() => {
              if (this.props.profile) {
                this.props.history.push('/profile/' + this.props.profile + '/posts/' + post.id);
              } else {
                this.props.history.push('/posts/' + post.id);
              }
            } } >{this.displaySnippet()} - {post.candidate.first_name + ' ' + post.candidate.last_name}</p>
            <div className="tw-flex tw-flex-row tw-justify-between">
              <div className="tw-flex tw-flex-row tw-space-x-4 tw-py-2">
                <div className="tw-flex tw-flex-row tw-cursor-pointer" onClick={() => { authOverlay() && this.addLike(); }}>
                  {this.state.likeLoading == true ? <ReactLoading type="spin" color="#ECC94B" height={24} width={24} /> : <>
                    <span className="tw-mr-2">{this.state.amountLiked}</span>
                    {this.getLiked()}
                  </>}
                </div>
                <img src={comment} className="tw-cursor-pointer" style={{ height: 24, width: 24 }} onClick={() => {
                  if (this.props.profile) {
                    this.props.history.push('/profile/' + this.props.profile + '/posts/' + post.id);
                  } else {
                    this.props.history.push('/posts/' + post.id);
                  }
                } }/>
              </div>
              <div className="tw-flex tw-flex-row tw-space-x-4 tw-py-2 tw-cursor-pointer">
                <img onClick={() => { this.share(); }} src={share} style={{ height: 24, width: 24 }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation('common')(withRouter(PostComponent));
