import React, { Component } from 'react';
import Screen from 'components/templates/Screen';
import { withTranslation } from 'react-i18next';
import instance from 'axiosInstance';
import ShopProduct from './../../components/shop/ShopProduct';
import Pagination from './../../components/shop/Pagination';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';
import { getAmountInCart } from 'utils';
export class Shop extends Component {
  constructor () {
    super();
    this.state = { loadingProducts: true, loadingBestSellers: true, componentsLoading: false, products: [], bestSellers: [], count: 1, cartSize: getAmountInCart() };
    this.getProducts = this.getProducts.bind(this);
  }

  componentDidMount () {
    instance.get('/products/count').then((s) => {
      this.setState({ count: s.data.count });
    });
    this.getProducts();
  }

  getProducts () {
    this.setState({ loadingProducts: true, loadingBestSellers: true });
    const params = queryString.parse(this.props.location.search);
    if (!params.page || parseInt(params.page) === 1) {
      instance.get('/products').then((s) => {
        this.setState({ loadingProducts: false, products: s.data.products });
      });
      instance.get('/products/bestSellers').then((s) => {
        this.setState({ loadingBestSellers: false, bestSellers: s.data.products });
      });
    } else {
      instance.get('/products/page/' + params.page).then((s) => {
        this.setState({ loadingProducts: false, loadingBestSellers: false, products: s.data.products });
      });
    }
  }

  render () {
    const { products, bestSellers } = this.state;
    const params = queryString.parse(this.props.location.search);
    return (
      <Screen type="gray" cartSize={this.state.cartSize} loading={(this.state.loadingProducts !== false && this.state.loadingBestSellers !== false)} title={this.props.t('pages:shop')}>
        <div className="container">
          <Pagination update={this.getProducts} count={this.state.count} page={params.page || 1} />
          {products.length === 0
            ? <div className="tw-card">
              <p>{this.props.t('noProductPage')}</p>
            </div>

            : <>
              {(!params.page || parseInt(params.page) === 1) &&
          <div className="tw-mb-8 tw-text-white">
            <h2 className="tw-text-xl tw-font-bold tw-mb-2">{this.props.t('bestSellers')}</h2>
            <div className="row">
              {bestSellers.map(product => <ShopProduct cartUpdate={(n) => { this.setState({ cartSize: n }); }} key={product.id} product={product} />)}
            </div>
          </div>}

              <div>
                <h2 className="tw-text-xl tw-font-bold tw-mb-2 tw-text-white">{this.props.t('newProducts')}</h2>
                <div className="row">
                  {products.map(product => <ShopProduct cartUpdate={(n) => { this.setState({ cartSize: n }); }} key={product.id} product={product} />)}
                </div>
              </div>
            </>}
          <Pagination update={this.getProducts} count={this.state.count} page={params.page || 1} />
        </div>
      </Screen>
    );
  }
}

export default withRouter(withTranslation('common')(Shop));
