import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

export class SearchBar extends Component {
  constructor (props) {
    super(props);
    this.state = { search: this.props.search };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange (e) {
    this.setState({ search: e.target.value });
  }

  handleSubmit (e) {
    e.preventDefault();
    this.props.newSearch(this.state.search);
  }

  render () {
    return (
      <div className="tw-flex tw-flex-row tw-text-sm" style={{ height: '75%', background: '#636363' }}>
        <input onKeyDown={
          (e) => {
            e.keyCode === 13 && this.handleSubmit(e);
          }
        } value={this.state.search} placeholder={this.props.t('findJobOffer')} onChange={this.handleChange} type="text" className="tw-p-3 tw-text-xl tw-flex-1 tw-h-full" style={{ height: '100%', background: '#636363' }}/>
        <img onClick={this.handleSubmit} src="https://s.svgbox.net/hero-solid.svg?ic=search&fill=white" width="30" className="tw-cursor-pointer tw-mr-1 tw-ml-1" />
      </div>
    );
  }
}

export default withTranslation(['common'])(SearchBar);
