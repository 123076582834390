import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Screen from 'components/templates/Screen.js';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import instance from './../../axiosInstance';

export class Policies extends Component {
  constructor () {
    super();
    this.state = { loading: true };
  }

  componentDidMount () {
    instance.get('/products/policies/' + this.props.match.params.id).then(s => {
      this.setState({ employer: s.data.employer, loading: false });
    });
  }

  render () {
    const { employer } = this.state;
    const { t } = this.props;
    return (
      <Screen type="shop" loading={this.state.loading} title={this.props.t('pages:policies')}>
        {this.state.loading === false &&
        <div className="container">
          <div className="row">
            <div className="col-lg-8 tw-h-64 mx-auto">
              <div className="tw-card">
                <p onClick={() => { this.props.history.goBack(); }} className="tw-text-white tw-text-xl tw-underline hover:tw-no-underline tw-cursor-pointer">
                  {this.props.t('goBack')}
                </p>
                <h2 className="tw-text-xl tw-font-bold">{t('pages:policies')} {t('of')} {employer.name}</h2>
                <h3 className="tw-text-lg tw-font-semibold">{t('refunds')}</h3>
                <p>{employer.refunds}</p>
                <h3 className="tw-text-lg tw-font-semibold">{t('deliveryPickup')}</h3>
                <p>{employer.delivery_pickup_conditions}</p>
              </div>
            </div>
          </div>
        </div>}
      </Screen>
    );
  }
}

Policies.propTypes = {
  t: PropTypes.func.isRequired
};

export default withRouter(withTranslation('common')(Policies));
