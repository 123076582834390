import React, { Component } from 'react';
import * as Cookies from 'js-cookie';
import instance from 'axiosInstance';
import { Redirect } from 'react-router-dom';

export class Logout extends Component {
  componentDidMount () {
    instance.get('/logout', { headers: { Authorization: 'Bearer ' + Cookies.get('auth') } });
    Cookies.remove('auth');
    Cookies.remove('email_verified');
  }

  render () {
    return <Redirect to="/" />;
  }
}

export default Logout;
