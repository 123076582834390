import React, { Component } from 'react';
import location from 'img/icons/location.svg';
import ReactLoading from 'react-loading';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

export class CompanyHeaderShop extends Component {
  getEmployerLogo () {
    const { employer } = this.props;
    var logoStyle = {};
    var textStyle = {};
    if (employer.banner) {
      logoStyle = { transform: 'translateY(-50px)', height: 100 };
      textStyle = { transform: 'translateY(-40px)' };
    }
    return (
      <div className="tw-flex tw-flex-col tw-items-center">
        {employer.banner ? <div style={{ height: 200 }} className="tw-bg-cover tw-bg-center">
          <img src={(process.env.REACT_APP_STORAGE_URL + employer.banner).replace(/([^:]\/)\/+/g, '$1')} className="tw-h-full" />
        </div> : null}
        { employer.logo ? <div style={logoStyle} className="tw-bg-cover tw-bg-center">
          <img src={(process.env.REACT_APP_STORAGE_URL + employer.logo).replace(/([^:]\/)\/+/g, '$1')} className=" tw-h-full tw-object-cover" />
        </div> : null}
        <p style={textStyle}>{employer.description}</p>
      </div>
    );
  }

  getTitle () {
    const { employer } = this.props;
    if (employer.refunds !== undefined && employer.delivery_pickup_conditions !== undefined) {
      return <p onClick={() => { this.props.history.push('/shop/' + employer.id); }} className="tw-text-xl tw-font-bold tw-underline tw-cursor-pointer hover:tw-no-underline">{ employer.name }</p>;
    } else {
      return <p className="tw-text-xl tw-font-bold">{ employer.name }</p>;
    }
  }

  render () {
    const { employer } = this.props;
    return (
      <div className="col-lg-12">
        <div className="bg-fullgray tw-items-center tw-text-center tw-flex tw-flex-col tw-mb-4">
          {this.props.loading === true
            ? <ReactLoading type="spin" color="#ECC94B" />
            : <>
              {this.getEmployerLogo()}
            </>
          }
        </div>
      </div>

    );
  }
}

export default withRouter(withTranslation('common')(CompanyHeaderShop));
