import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Screen from 'components/templates/Screen';
import { withTranslation } from 'react-i18next';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import PostComponent from 'components/social/PostComponent';
import instance from 'axiosInstance';
import { Button } from 'components/common/Button';
import { authOverlay, getAmountInCart, forceOpenOverlay } from 'utils';
import SearchBar from 'components/social/SearchBar';
import FieldComponent from 'components/social/FieldComponent';
import { withRouter } from 'react-router-dom';
import * as Cookies from 'js-cookie';
import welcome from 'videos/welcome.gif';

export class Welcome extends Component {
  constructor () {
    super();
    this.state = { loading: true, fields: [] };
  }

  componentDidMount () {
    this.getFields();
    Cookies.set('welcomeSeen', true);
  }

  getFields () {
    instance.get('/fields').then(s => {
      this.setState({ fields: s.data.fields });
    });
  }

  render () {
    const { t } = this.props;
    const { fields, loading } = this.state;
    return (
      <Screen paddingTop={false} loading={false} type="gray">
        <div className="tw-full">
          <img className="tw-w-full" src={welcome} />
          {/* <video width="100%" className="tw-full" autoPlay loop>
            <source src={welcome} type="video/mp4" />
            <p className="tw-mb-8">{this.props.t('discoverOffers')}</p>
          </video> */}
        </div>
        <div className="tw-mx-auto tw-container">
          <div className="tw-flex md:tw-flex-row md:tw-justify-around tw-flex-col tw-justify-center tw-items-center tw-mb-8">
            <div onClick={() => { this.props.history.push('/login') }} className="tw-flex tw-flex-row tw-justify-center focus:tw-bg-yellow-700 tw-bg-aa-yellow tw-px-16 tw-py-3 tw-font-bold  tw-text-2xl tw-m-1 tw-self-end tw-leading-none tw-min-w-32 tw-text-black tw-cursor-pointer tw-flex tw-flex-row focus:tw-outline-none tw-w-3/4 md:tw-w-1/3 tw-text-center tw-mx-auto md:tw-mx-0 tw-text-white">
              <span>{this.props.t('professional')}</span>
            </div>
            <div onClick={() => { window.location.href = 'https://employers.alpeconception.com/employers'; }} className="tw-flex tw-flex-row tw-justify-center focus:tw-bg-yellow-700 tw-bg-aa-yellow tw-px-16 tw-py-3 tw-font-bold  tw-text-2xl tw-m-1 tw-self-end tw-leading-none tw-min-w-32 tw-text-black tw-cursor-pointer tw-flex tw-flex-row focus:tw-outline-none tw-w-3/4 md:tw-w-1/3 tw-text-center tw-mx-auto md:tw-mx-0 tw-text-white">
              <span>{this.props.t('company')}</span>
            </div>
          </div>
        </div>
        <ResponsiveMasonry
          columnsCountBreakPoints={{ 350: 2, 750: 4, 900: 5, 1200: 6 }}
        >
          <Masonry>
            {fields.map(f => {
              return (<FieldComponent key={f.id} field={f} />);
            })}
          </Masonry>
        </ResponsiveMasonry>
      </Screen>
    );
  }
}

Welcome.propTypes = {
  t: PropTypes.func.isRequired
};

export default withTranslation('common')(withRouter(Welcome));
