import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Screen from 'components/templates/Screen.js';
import PropTypes from 'prop-types';
import instance from 'axiosInstance';
import { withRouter } from 'react-router-dom';
import profile from 'img/placeholder/profile.svg';
import location from 'img/icons/location.svg';
import { authOverlay, isAuth } from 'utils';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import PostComponent from 'components/social/PostComponent';
import ReactLoading from 'react-loading';
import Post from 'pages/coffeeMachine/Post';
import { Button } from '../../components/common/Button';
import CreatePost from '../coffeeMachine/CreatePost';

export class PublicProfile extends Component {
  constructor (props) {
    super(props);
    this.state = { candidate: {}, loadingDetails: true, loadingPosts: true, status: 'loading', posts: [], likes: [], createPostOpened: false };
    this.getPosts = this.getPosts.bind(this);
  }

  componentDidMount () {
    instance.get('/candidate/details/' + this.props.match.params.id).then((s) => {
      this.setState({ candidate: s.data.candidate, loadingDetails: false });
    });
    this.getPosts();
  }

  getProfilePic () {
    const { candidate } = this.state;
    if (!candidate.profile_pic) {
      return profile;
    }
    return (process.env.REACT_APP_STORAGE_URL + candidate.profile_pic).replace(/([^:]\/)\/+/g, '$1');
  }

  getRelationStatus () {
    if (isAuth()) {
      return instance.get('/relationships/status/' + this.props.match.params.id).then(s => {
        this.setState({ status: s.data.status });
      });
    } else {
      this.setState({ status: 'none' });
    }
  }

  getPosts () {
    instance.get('/candidate/posts/' + this.props.match.params.id).then(s => {
      this.setState({ loadingPosts: false, posts: s.data.posts, likes: s.data.likes });
    });
  }

  getLiked (post) {
    var likes = this.state.likes.filter(like => like.post_id == post.id);
    if (likes.length == 0) {
      return false;
    }
    return true;
  }

  getButton () {
    const { t } = this.props;
    if (this.state.status !== 'loading') {
      if (this.state.status === 'pending') {
        return (<div onClick={() => { this.removeRequest(); }} className="tw-bg-yellow-700 tw-h-16 tw-full tw-flex tw-flex-row tw-justify-center tw-items-center  tw-cursor-pointer focus:tw-bg-gray-700 tw-shadow-lg">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M6.188 8.719c.439-.439.926-.801 1.444-1.087 2.887-1.591 6.589-.745 8.445 2.069l-2.246 2.245c-.644-1.469-2.243-2.305-3.834-1.949-.599.134-1.168.433-1.633.898l-4.304 4.306c-1.307 1.307-1.307 3.433 0 4.74 1.307 1.307 3.433 1.307 4.74 0l1.327-1.327c1.207.479 2.501.67 3.779.575l-2.929 2.929c-2.511 2.511-6.582 2.511-9.093 0s-2.511-6.582 0-9.093l4.304-4.306zm6.836-6.836l-2.929 2.929c1.277-.096 2.572.096 3.779.574l1.326-1.326c1.307-1.307 3.433-1.307 4.74 0 1.307 1.307 1.307 3.433 0 4.74l-4.305 4.305c-1.311 1.311-3.44 1.3-4.74 0-.303-.303-.564-.68-.727-1.051l-2.246 2.245c.236.358.481.667.796.982.812.812 1.846 1.417 3.036 1.704 1.542.371 3.194.166 4.613-.617.518-.286 1.005-.648 1.444-1.087l4.304-4.305c2.512-2.511 2.512-6.582.001-9.093-2.511-2.51-6.581-2.51-9.092 0z"/></svg>                <span className="tw-font-bold tw-text-xl tw-ml-4">{t('networkPending')}</span>
        </div>);
      }
      if (this.state.status === 'accepted') {
        return (<div onClick={() => { this.removeRequest(); }}className="tw-bg-red-700 tw-h-16 tw-full tw-flex tw-flex-row tw-justify-center tw-items-center  tw-cursor-pointer focus:tw-bg-gray-700 tw-shadow-lg">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M6.188 8.719c.439-.439.926-.801 1.444-1.087 2.887-1.591 6.589-.745 8.445 2.069l-2.246 2.245c-.644-1.469-2.243-2.305-3.834-1.949-.599.134-1.168.433-1.633.898l-4.304 4.306c-1.307 1.307-1.307 3.433 0 4.74 1.307 1.307 3.433 1.307 4.74 0l1.327-1.327c1.207.479 2.501.67 3.779.575l-2.929 2.929c-2.511 2.511-6.582 2.511-9.093 0s-2.511-6.582 0-9.093l4.304-4.306zm6.836-6.836l-2.929 2.929c1.277-.096 2.572.096 3.779.574l1.326-1.326c1.307-1.307 3.433-1.307 4.74 0 1.307 1.307 1.307 3.433 0 4.74l-4.305 4.305c-1.311 1.311-3.44 1.3-4.74 0-.303-.303-.564-.68-.727-1.051l-2.246 2.245c.236.358.481.667.796.982.812.812 1.846 1.417 3.036 1.704 1.542.371 3.194.166 4.613-.617.518-.286 1.005-.648 1.444-1.087l4.304-4.305c2.512-2.511 2.512-6.582.001-9.093-2.511-2.51-6.581-2.51-9.092 0z"/></svg>                <span className="tw-font-bold tw-text-xl tw-ml-4">{t('networkRemove')}</span>
        </div>);
      }
      if (this.state.status === 'none') {
        return (<div onClick={() => { authOverlay() && this.sendRelationshipRequest(); }}className="tw-bg-green-300 tw-h-16 tw-full tw-flex tw-flex-row tw-justify-center tw-items-center  tw-cursor-pointer focus:tw-bg-gray-700 tw-shadow-lg">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M6.188 8.719c.439-.439.926-.801 1.444-1.087 2.887-1.591 6.589-.745 8.445 2.069l-2.246 2.245c-.644-1.469-2.243-2.305-3.834-1.949-.599.134-1.168.433-1.633.898l-4.304 4.306c-1.307 1.307-1.307 3.433 0 4.74 1.307 1.307 3.433 1.307 4.74 0l1.327-1.327c1.207.479 2.501.67 3.779.575l-2.929 2.929c-2.511 2.511-6.582 2.511-9.093 0s-2.511-6.582 0-9.093l4.304-4.306zm6.836-6.836l-2.929 2.929c1.277-.096 2.572.096 3.779.574l1.326-1.326c1.307-1.307 3.433-1.307 4.74 0 1.307 1.307 1.307 3.433 0 4.74l-4.305 4.305c-1.311 1.311-3.44 1.3-4.74 0-.303-.303-.564-.68-.727-1.051l-2.246 2.245c.236.358.481.667.796.982.812.812 1.846 1.417 3.036 1.704 1.542.371 3.194.166 4.613-.617.518-.286 1.005-.648 1.444-1.087l4.304-4.305c2.512-2.511 2.512-6.582.001-9.093-2.511-2.51-6.581-2.51-9.092 0z"/></svg>                <span className="tw-font-bold tw-text-xl tw-ml-4">{t('addNetwork')}</span>
        </div>);
      }
    } else {
      this.getRelationStatus();
    }
  }

  sendRelationshipRequest () {
    var fd = new FormData();
    this.setState({ loading: true });
    fd.append('receiver_id', this.props.match.params.id);
    instance.post('/relation-request', fd).then(() => {
      this.getRelationStatus().then(s => {
        this.setState({ loading: false });
      });
    }, () => {
      this.getRelationStatus().then(s => {
        this.setState({ loading: false });
      });
    });
  }

  removeRequest () {
    if (window.confirm(this.props.t('deleteRelationship'))) {
      var fd = new FormData();
      this.setState({ loading: true });
      fd.append('receiver_id', this.props.match.params.id);
      instance.post('/relation-request/delete', fd).then(() => {
        this.getRelationStatus().then(s => {
          this.setState({ loading: false });
        });
      }, () => {
        this.getRelationStatus().then(s => {
          this.setState({ loading: false });
        });
      });
    }
  }

  render () {
    const { t } = this.props;
    const { candidate, posts } = this.state;
    const { images } = candidate;
    console.log(candidate, candidate.me);
    return (
      <Screen type="gray" loading={this.state.loadingDetails} title={this.props.t('pages:profileOf') + ' ' + candidate.first_name + ' ' + candidate.last_name}>
        
        
        <CreatePost profile={candidate.id} opened={this.state.createPostOpened} closeModal={() => { this.setState({ createPostOpened: false }); }} />
       
        <div className="container">
          <div className="row">
            {this.props.location.pathname.split('/')[4] !== undefined && <Post mine={this.state.status === 'same'} profile={candidate.id} postId={this.props.location.pathname.split('/')[4]} />}
            <div className="col-lg-3">
              <div className="bg-full-gray tw-mb-4 tw-text-white tw-mx-auto lg:tw-w-full tw-w-1/2">
                <img src={this.getProfilePic()} className="tw-w-full tw-object-cover" />
                <div className="tw-items-center tw-flex tw-flex-col tw-p-3">
                  <p className="tw-text-xl tw-font-bold">{ candidate.first_name + ' ' + candidate.last_name }</p>
                  <p className="tw-text-center">{candidate.bio}</p>
                  <div className="tw-flex tw-flex-row tw-justify-between tw-w-full">
                    <div className="tw-flex tw-flex-row tw-items-center">
                      <span>{candidate.likes_count}</span>
                      <img className="tw-ml-1" src="https://s.svgbox.net/hero-solid.svg?ic=heart&fill=fff" width="24" height="24" />
                      <span className="tw-ml-2">{candidate.relations_count}</span>
                      <img className="tw-ml-1" src="https://s.svgbox.net/hero-solid.svg?ic=link&fill=fff" width="24" height="24" />
                    </div>
                    {candidate.city !== undefined && <div className="tw-items-center tw-flex tw-flex-row tw-self-end tw-mt-2">
                      <img className="tw-mr-1" src={location} />
                      <span className="tw-text-white tw-font-semibold">{ candidate.city }</span>
                    </div>}

                  </div>
                </div>
              </div>
              {this.getButton()}
            </div>
            <div className="col-lg-9">
            {candidate?.me == true ?  <div className="tw-flex tw-flex-row" style={{transform: 'translateX(-20px)'}}>
                <Button text={t('addPost')} action={() => { this.setState({ createPostOpened: true }); }} />
              </div> : null}
              <div className="row">
              {(posts.length === 0)
                ? this.state.loadingPosts === false ? <div className="tw-card tw-flex tw-flex-row tw-justify-center tw-items-center tw-w-full"><p>{this.props.t('noPosts')}</p></div> : <div className="tw-card tw-flex tw-flex-row tw-justify-center tw-items-center tw-w-full"><ReactLoading type="spin" color="#ECC94B" /></div>
                : <Masonry>
                  {posts.map(p => {
                    return (<PostComponent profile={candidate.id} key={p.id} liked={this.getLiked(p)} post={p} />);
                  })}
                </Masonry>}
              </div>

            </div>
          </div>

        </div>
      </Screen>
    );
  }
}

PublicProfile.propTypes = {
  t: PropTypes.func.isRequired
};

export default withRouter(withTranslation('common')(PublicProfile));
