/* eslint-disable react/jsx-key */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import comment from 'img/icons/comment.svg';
import share from 'img/icons/share.svg';
import ReactLoading from 'react-loading';
import { withTranslation } from 'react-i18next';
import { objt } from 'utils';

export class FieldComponent extends Component {

  getSource(t) {
    if(t?.post?.still) {
      return ('https://api.alpeconception.com/storage/' + t.post.still).replace(/([^:]\/)\/+/g, '$1');
    }
    if(t?.thumbnail) {
      return ('https://api.alpeconception.com/storage/' + t.thumbnail).replace(/([^:]\/)\/+/g, '$1');
    }
  }
  render () {
    const { field } = this.props;
    return (
      <div className="tw-w-full tw-p-1 tw-flex tw-flex-col tw-justify-center tw-items-center tw-cursor-pointer" onClick={() => this.props.history.push({ pathname: '/', state: { search: objt(field) } })}>
        <span className="tw-text-xl tw-text-white tw-mt-2">{ objt(field) }</span>
        <div className=" tw-w-full tw-grid-cols-2 tw-grid tw-grid-rows-2" style={{ background: '#434349', height: 300 }}>
          {field.thumbnails.map(t => (
            <div className="tw-flex tw-flex-row tw-justify-center tw-items-center tw-p-1 " >
              <img className=" tw-object-cover tw-h-full tw-w-full" src={this.getSource(t)} />
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default withTranslation('common')(withRouter(FieldComponent));
