import React, { Component } from 'react';
import Screen from 'components/templates/Screen';
import Submit from 'components/forms/Submit';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import SecondaryButton from 'components/common/SecondaryButton';

export class LogoutPage extends Component {
  render () {
    return (
      <Screen type="gray" containerClasses="tw-h-full tw-flex tw-flex-row tw-justify-center tw-items-center" title={this.props.t('pages:logout')}>
        <div style={{ width: '350px', position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} className="tw-card tw-p-4 tw-text-center tw-flex tw-flex-col tw-items-center">
          <p>{this.props.t('logoutConfirmation')}</p>
          <div className="tw-flex tw-flex-row tw-items-center tw-justify-between">
            <SecondaryButton
              action={() => { this.props.history.goBack(); }}
              text={this.props.t('goBack')} />
            <Submit action={() => { this.props.history.push('/logout'); }} title={this.props.t('pages:logout')} />
          </div>
        </div>
      </Screen>
    );
  }
}

export default withRouter(withTranslation(['common', 'pages'])(LogoutPage));
