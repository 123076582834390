var en = {
  input: {
    required: '(required)',
    upload: 'Upload',
    noFileSelected: 'No file selected'
  },
  pages: {
    askDemo: 'Ask a demo',
    sendApplication: 'Create my profile',
    coffeeMachine: 'Coffee Machine',
    newsFeed: 'News Feed',
    messages: 'Messages',
    login: 'Login',
    myProfile: 'My profile',
    modifyProfile: 'Modify my profile',
    jobs: 'Jobs',
    verifyEmail: 'Verify your email',
    verifyNewEmail: 'New Email Verification',
    emailVerified: 'Email Verified',
    settings: 'Settings',
    terms: 'Terms and Conditions',
    newEmailVerified: 'New Email Verification',
    comingSoon: 'Coming Soon!',
    logout: 'Log out',
    virtualOffice: 'Virtual office',
    companies: 'Companies',
    shop: 'Shop',
    cart: 'Cart',
    orderSuccess: 'Order Success!',
    policies: 'Policies',
    profileOf: 'Profile of',
    createPost: 'Create a post',
    post: 'Post',
    home: 'Home',
    profile: 'Profile',
    modifyPost: 'Modify a post',
    dashboard: 'Dashboard',
    managePosts: 'Manage my posts'
  },
  common: {
    first_name: 'First name',
    last_name: 'Last name',
    email: 'Email',
    email_confirmation: 'Email confirmation',
    phone_number: 'Phone Number',
    postal: 'Postal Code',
    city: 'City',
    field_of_work: 'Field of work',
    training: 'Training',
    work_experiences: 'Work Experiences (job, company, date)',
    salary_id: 'Preferred Salary',
    availability: 'Availability',
    job_type: 'Job type',
    interests: 'Interests',
    cvF: 'Curriculum Vitae (CV)',
    uploadCVFormats: 'Accepted formats: pdf, docx',
    cv: 'Upload my CV',
    video_cv: 'Video CV',
    uploadVideoCVFormat: 'Accepted formats: any video file',
    uploadVideoCV: 'Upload my video CV',
    submit: 'Submit',
    businessName: 'Business Name',
    contactFirstName: 'Contact\'s First Name',
    contactLastName: 'Contact\'s Last Name',
    businessAddress: 'Office Address',
    officePhoneNumber: 'Office Phone Number',
    cellPhoneNumber: 'Cell Phone Number',
    login: 'Login',
    password: 'Password',
    password_confirmation: 'Password confirmation',
    noAccount: 'No account? Sign up',
    modify: 'Modify the informations',
    myInfos: 'My informations',
    myDocs: 'My documents',
    downloadCV: 'Download my CV',
    downloadVideoCV: 'Download my video CV',
    like: 'Like',
    comment: 'Comment',
    share: 'Share',
    verifyEmail: 'We sent you a verification email. Please, click on the button in the email to verify your email address. (check the "Spam" folder !)',
    verifyNewEmail: 'We sent you a verification email at {­{email}} to make sure it is yours.Please, click on the button in the email to verify your email address. (check the "Spam" folder !)',
    verifyNewEmailOthers: 'Your other profile modifications have been saved properly to your profile.',
    resendEmail: 'Resend the email',
    emailResent: 'Email resent!',
    emailVerified: 'Your email has been verified. You may now use the website.',
    backToHome: 'Go back to the initial page',
    noVideoCV: 'You didn\'t send a video CV yet',
    generalInformations: 'General Informations',
    professionalInformations: 'Professional Informations',
    documents: 'Files',
    undefined: 'Undefined',
    confirmPasswordToGoForward: 'Type your password to accept the edits',
    language: 'Language',
    fr: 'French',
    en: 'English',
    readTerms: 'Read the Terms and Conditions',
    goBack: 'Go Back',
    fixErrors: 'There was an error with your request. Please, check all your input.',
    newEmailVerified: 'Your new email has been verified. You must use this email to login from now on.',
    'email-used-new-email': 'This new email has been taken by an account other than yours.',
    'invalid-new-email-code': 'There has been an issue with the verification of your email. Please try again.',
    comingSoonText: 'This page/functionnality is not yet available. Visit the website regularly to see if it became available!',
    logoutConfirmation: 'Are you sure you want to logout ?',
    noCV: 'You didn\'t send a CV yet',
    checkEmail: 'I have verified my email',
    profile_pic: 'Profile picture',
    uploadProfilePic: 'Accepted formats: jpg, png',
    status: 'Status',
    pending: 'Validation pending',
    accepted: 'Accepted',
    refused: 'Refused, please send another video cv.',
    interested: 'I\'m interested!',
    notInterested: 'I\'m not interested',
    noMoreJobs: 'You have seen all the jobs offers corresponding to your profile. Come back later to see more of them!',
    searching: 'Searching for a job',
    searchingText: 'By picking "No", you risk missing opportunities of employers contacting you!',
    yes: 'Yes',
    no: 'No',
    'forgot-password': 'Forgot your password?',
    completeProfile: 'In order to apply to job offers, the fields "Postal Code", "City", "Fields of work", "Job Type", "Availabilities" and "CV" must be filled.',
    clientMode: 'Click here to signup as a company',
    candidateMode: 'Click here to signup as a candidate',
    delivery: 'Delivery',
    pickup: 'Pickup',
    'acquisition-method': 'Acquisition method',
    stores: 'Stores',
    quantityInCart: 'You have this item {{ quantity }} times in your cart.',
    addToCart: 'Add to the cart',
    cartEmpty: 'Your cart is empty',
    subTotal: 'Sub-total',
    checkout: 'Go to checkout',
    itemsRemovedOOS: 'These items have been removed from your cart since they were out of stock: ',
    itemsAdjusted: 'The number asked was higher than the stock for the items: {{ items }}. Adjust your quantity according to the maximum stock.',
    oosortoomuch: 'Out of stock/limit reached',
    noOrder: 'No order was found for this number',
    orderNumber: 'Order #{{ number }}',
    multipleOrdersNumber: 'If there are multiple order numbers, it\'s because you ordered products that came from different merchants. You must schedule pickup/delivery with them individually',
    policies: 'Refunds, Pickups and Delivery policies',
    of: 'of',
    refunds: 'Refunds',
    deliveryPickup: 'Delivery/Pickup',
    bestSellers: 'Best sellers',
    newProducts: 'New products',
    noProductPage: 'No product available for this page...',
    next: 'Next',
    previous: 'Previous',
    noProductFound: 'This product doesn\'t exist or has been deleted.',
    managePictures: 'Manage my pictures',
    addPictures: 'Add pictures',
    numberPics: 'Number of pictures you can add:',
    close: 'Close',
    sendSave: 'Send and save',
    filesErrors: 'Check that you haven\'t exceeded the limits and that all files are images',
    deleteImage: 'Are you sure you want to delete this picture?',
    networkPending: 'Request Pending',
    networkRemove: 'Remove from network',
    addNetwork: 'Add to the network',
    deleteRelationship: 'Delete this relation? This action cannot be undone.',
    manageNetwork: 'Manage my network',
    requestsReceived: 'Requests received',
    relationships: 'Relationships',
    requestsSent: 'Requests sent',
    accept: 'Accept',
    refuse: 'Refuse',
    cancel: 'Cancel',
    noDiscussions: "You haven't picked any discussion. Pick one or start a discussion !",
    employer: 'Employer',
    candidate: 'Candidate',
    sendMessage: 'Send a Message',
    thatsAll: 'You saw all the posts!',
    addPost: 'Add a post',
    text: 'Text',
    video_link: 'YouTube Video Link',
    'images[]': 'Images (max 8)',
    manageBusiness: 'Manage my business/shop',
    createBusiness: 'Create my business/shop',
    comments: 'Comments',
    delete: 'Delete',
    edit: 'Edit',
    copiedLink: 'Copied Link!',
    noPosts: 'No Posts!',
    signup: 'Signup',
    onboarding: 'Connect with professional and companies to know everything about the business opportunities',
    remainingC: 'remaining characters',
    notifications: 'Notifications',
    notifText: '{{ name }} and {{ number }} others commented your publication: ',
    notifDoubleText: '{{ name }} and {{ name2 }} commented your publication: ',
    notifSingleText: '{{ name }} commented under your post: ',
    deliveryFees: 'Delivery Fees',
    product: 'product',
    noDeliveryFees: 'Free delivery!',
    feed: 'Feed',
    inTheCart: 'in the cart',
    variantsCheckout: 'The selected options (size, color, etc) aren\'t displayed at checkout. Rest assured that you will have the product you paid for with the right options.',
    answerSent: 'You already sent your answer for this job offer!',
    seeOtherJobs: 'See the other job offers.',
    editYourProfile: 'If your profile isn\'t filled, fill it to see more offers!',
    noPhoneEmail: 'Your message cannot a phone number or an email.',
    clickPlus: 'You must click on the green button next to your answer to add it. If the button next to your answer isn\'t red, it isn\'t sent',
    freeDelivery: 'Free delivery at {{ company }} starting from {{ minimum }}$ bought from them',
    bio: 'Professional title',
    driver: 'Do you have a driver licence?',
    car: 'Do you have a car?',
    student_permit: 'Do you have a student permit?',
    work_permit: 'Do you have a work permit?',
    permanence: 'Do you have your permanence?',
    citizen: 'Are you a Canadian citizen?',
    rebateToApply: 'Rebate to apply:',
    useCoupon: '(use a coupon?)',
    usePromo: '(use a promotion?)',
    findJobOffer: 'Find a job offer',
    company: 'Company',
    professional: 'Professional',
    discoverOffers: 'Discover all the available offers for your searching profile',
    addJobOffer: 'Post a job offer'
  }
};

export default en;
