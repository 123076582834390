import React, { Component } from 'react';
import Screen from 'components/templates/Screen';
import Form from 'components/forms/Form';
import TextInput from 'components/forms/TextInput';
import Submit from 'components/forms/Submit';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import * as Cookies from 'js-cookie';
import { connect } from 'react-redux';
import instance from 'axiosInstance';
import { addErrors } from 'redux/actions';
import { formToFormData } from 'utils';
import { withRouter, Link } from 'react-router-dom';

export class Login extends Component {
  constructor (props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = { loading: false };
  }

  pushHistory () {
    if (Cookies.get('origin') !== undefined) {
      var origin = Cookies.get('origin');
      Cookies.remove('origin');
      this.props.history.push(origin);
    } else {
      this.props.history.push('/');
    }
  }

  handleSubmit (e) {
    e.preventDefault();
    this.setState({ loading: true });
    var fd = formToFormData(['email', 'password'], this.props.form);
    instance.post('/login', fd).then((success) => {
      Cookies.set('auth', success.data.token);
      Cookies.set('email_verified', success.data.email_verified);
      if (Cookies.get('job_offer')) {
        instance.get('/job-offer-without-email?job_offer=' + Cookies.get('job_offer'));
        Cookies.remove('job_offer');
      }
      if (success.data.email_verified === true) {
        if (this.props?.history?.location?.state?.from?.pathname) {
          this.props.history.push(this.props.history.location.state.from.pathname);
        }
        this.pushHistory();
      } else {
        this.props.history.push('/verify-email');
      }
    }, (error) => {
      this.setState({ loading: false });
      this.props.addErrors(error.response.data.errors, 'login');
    });
  }

  render () {
    return (
      <Screen type="triangle" loading={this.state.loading} containerClasses="tw-h-full tw-flex tw-flex-row tw-justify-center tw-items-center" title={this.props.t('pages:login')}>
        <div style={{ width: '350px', position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} className="tw-card tw-p-4">
          <Form submitOnEnter={false} onSubmit={this.handleSubmit} name="login" classes="tw-space-y-3">
            <TextInput name="email" type="email" />
            <TextInput name="password" type="password" />
            <a href={process.env.REACT_APP_FORGOT_PASSWORD} className="tw-font-semibold tw-text-sm tw-text-gray-200 tw-cursor-pointer tw-underline hover:tw-no-underline">{this.props.t('forgot-password')}</a>
            <div className="tw-flex flex-row tw-justify-between tw-items-center tw-mt-4">
              <Link to="/signup" className="tw-font-semibold tw-text-sm tw-text-gray-200 tw-cursor-pointer tw-underline hover:tw-no-underline">{this.props.t('noAccount')}</Link>
              <Submit title={this.props.t('login')} />
            </div>
          </Form>
        </div>
      </Screen>
    );
  }
}

Login.propTypes = {
  t: PropTypes.func.isRequired
};

Login.defaultProps = {
  form: {}
};

const mapStateToProps = ({ input }) => {
  return {
    form: input.login
  };
};

export default withRouter(connect(mapStateToProps, { addErrors })(withTranslation(['common', 'pages'])(Login)));
