import { SHOW_OVERLAY, REMOVE_OVERLAY, HANDLE_CHANGE, CLEAR_FORM, ADD_ERRORS, LIST_ADD, LIST_EDIT, LIST_REMOVE, LIST_DESC_ADD, LIST_DESC_EDIT, FORCE_OVERLAY } from './actionTypes';
import { forceOpenOverlay } from 'utils';

export const handleChange = (name, value, form) => ({
  type: HANDLE_CHANGE,
  payload: {
    name,
    value,
    form
  }
});

export const listAdd = (name, value, form) => ({
  type: LIST_ADD,
  payload: {
    name,
    value,
    form
  }
});

export const listEdit = (name, value, form, id) => ({
  type: LIST_EDIT,
  payload: {
    name,
    value,
    form,
    id
  }
});

export const listRemove = (name, form, id) => ({
  type: LIST_REMOVE,
  payload: {
    name,
    id,
    form
  }
});

export const listDescAdd = (name, value, training, form) => ({
  type: LIST_DESC_ADD,
  payload: {
    name,
    value,
    training,
    form
  }
});

export const listDescEdit = (name, value, training, form, id) => ({
  type: LIST_DESC_EDIT,
  payload: {
    name,
    value,
    training,
    form,
    id
  }
});

export const clearForm = form => ({
  type: CLEAR_FORM,
  payload: {
    form
  }
});

export const addErrors = (errors, form) => ({
  type: ADD_ERRORS,
  payload: {
    errors,
    form
  }
});

export const showOverlay = () => ({
  type: SHOW_OVERLAY
});

export const removeOverlay = () => ({
  type: REMOVE_OVERLAY
});

export const forceOverlay = () => ({
  type: FORCE_OVERLAY
});
