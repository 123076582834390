import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Screen from 'components/templates/Screen';
import { withTranslation } from 'react-i18next';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import PostComponent from 'components/social/PostComponent';
import instance from 'axiosInstance';
import ReactLoading from 'react-loading';
import { Button } from 'components/common/Button';
import CreatePost from './CreatePost';
import Post from './Post';
import { authOverlay, getAmountInCart } from 'utils';
import SearchBar from 'components/social/SearchBar';
import * as Cookies from 'js-cookie';

export class CoffeeMachine extends Component {
  constructor () {
    super();
    this.state = { seed: 0, loading: true, posts: [], pinned: [], likes: [], nextLink: 'tbd', done: false, createPostOpened: false, search: '', hashtags: [], cartSize: getAmountInCart() };
  }

  componentDidMount () {
    if(this.props?.location?.state?.search) {
      this.getPosts(this.props.location.state.search);
      this.setState({search: this.props.location.state.search});
    } else {
      this.setState({seed: Math.floor(Math.random() * (1000 - 1 + 1) + 1)}, () => {
        this.getPosts();
      });  
    }
    this.getHashtags();
  }

  getHashtags() {
    instance.get('/hashtags').then(s => {
      this.setState({hashtags: s.data.hashtags})
    });
  }

  getPosts (s = undefined) {
    if(s != undefined && s != '') {
      if(s[0] == '#') {
        s = s.substr(1);
      }
      instance.get('/posts/search?search=' + s).then(s => {
        var originalData = s.data.posts.data;
        while(s.data.posts.data.length < 40) {
          s.data.posts.data = s.data.posts.data.concat(originalData);
        }
        this.setState({ loading: false, posts: s.data.posts.data, pinned: [], nextLink: s.data.posts.next_page_url });
      });
    } else {
      instance.get('/posts?seed=' + this.state.seed).then(s => {
        this.setState({ loading: false, pinned: s.data.pinned, posts: s.data.posts.data, nextLink: s.data.posts.next_page_url, likes: s.data.likes });
      });  
    }
  }

  handleScroll = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      if(!this.state.nextLink) {
        this.setState({done: true});
        this.setState({posts: this.state.posts.concat(this.state.posts)})
      } else {
        const url =  (new URL(this.state.nextLink));
        instance.get(url.pathname.substr(5) + url.search).then(s => {
          this.setState(state => {
            const posts = state.posts.concat(s.data.posts.data);
            return {
              posts,
              nextLink: s.data.posts.next_page_url
            }
          });
        });
      } 
    }
  }

  getLiked(post) {
    var likes = this.state.likes.filter(like => like.post_id == post.id);
    if(likes.length == 0) {
      return false;
    }
    return true;
  }

  concated() {
    const {posts, pinned} = this.state;
    if(this.state.search) {
      return posts;
    }
    console.log(posts, pinned, this.state);
    var postsN = posts.filter(p => {
      return pinned.filter(pinned => pinned.id == p.id).length == 0;
    })
    return pinned.concat(postsN);
  }

  render () {
    const { t } = this.props;
    const { posts, pinned, loading, hashtags } = this.state;
    return (
      <Screen paddingTop={false} cartSize={this.state.cartSize} titleComp={<SearchBar search={this.state.search} newSearch={(s) => this.setState({loading: true, search: s}, this.getPosts(s))} />} loading={loading} type="gray" handleScroll={this.handleScroll} title={t('pages:coffeeMachine')}>
          {this.props.location.pathname.split('/')[2] !== undefined && <Post postId={this.props.location.pathname.split('/')[2]} />}
          <div className="tw-flex tw-flex-row tw-justify-start tw-flex-wrap tw-pt-1">
            <Button text={t('addPost')} action={() => {authOverlay() && this.setState({createPostOpened: true})}} />
            {/* AJOUTER AVEC LA PARTIE EMPLOYEUR MACHINE À CAFÉ */}
            {/* <Button text={t('addJobOffer')} action={() => {window.location.href = 'https://employers.alpeconception.com/employers'}} /> */}
            {hashtags.map(h => (
            <button {...this.props} onClick={() => this.setState({loading: true, search: h.hashtag}, this.getPosts(h.hashtag))} className="focus:tw-bg-gray-900 tw-bg-gray-700 tw-px-3 tw-py-2 tw-font-bold  tw-text-sm tw-m-1 tw-self-end tw-leading-none tw-min-w-32 tw-text-white tw-cursor-pointer tw-flex tw-flex-row focus:tw-outline-none ">
              {h.hashtag}
            </button>
            ))}

            <CreatePost opened={this.state.createPostOpened} closeModal={() => {this.setState({createPostOpened: false})}} />
          </div>
          <ResponsiveMasonry
            columnsCountBreakPoints={{ 350: 1, 750: 3, 900: 4, 1200: 6 }}
          >
            <Masonry>
              {this.concated().map(p => {
                return (<PostComponent cartUpdate={(n) => {this.setState({cartSize: n})}} key={p.id} liked={this.getLiked(p)} post={p} />);
              })}
            </Masonry>
          </ResponsiveMasonry>
          <div className="tw-flex tw-flex-row tw-justify-center">
            {this.state.done == true || posts.length < 40 || posts.length == 0 ? <div className="tw-card bg-434349"><p>{t('thatsAll')}</p></div>: <ReactLoading type="spin" color="#ECC94B" />}
          </div>
      </Screen>
    );
  }
}

CoffeeMachine.propTypes = {
  t: PropTypes.func.isRequired
};

export default withTranslation('common')(CoffeeMachine);
