/* eslint no-unused-vars: 0 */
import React, { Component } from 'react';
import * as Cookies from 'js-cookie';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import CoffeeMachine from 'pages/coffeeMachine/CoffeeMachine';

export function CoffeeMachineRoute ({ component: Component, email, props, reverse = false, ...rest }) {
  if (Cookies.get('welcomeSeen') === undefined) {
    return <Redirect
      to={{ pathname: '/welcome' }}
    />;
  }

  return (
    <Route path="/" component={CoffeeMachine} />
  );
}

CoffeeMachineRoute.propTypes = {
  component: PropTypes.node.isRequired,
  reverse: PropTypes.bool
};

export default CoffeeMachineRoute;
