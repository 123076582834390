import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { formToFormData } from 'utils';
import Screen from 'components/templates/Screen.js';
import PropTypes from 'prop-types';
import instance from 'axiosInstance';
import TextInput from 'components/forms/TextInput';
import Form from 'components/forms/Form';
import FormGroup from 'components/forms/FormGroup';
import FileInput from 'components/forms/FileInput';
import Submit from 'components/forms/Submit';
import DropdownMultiple from 'components/forms/DropdownMultiple';
import YesNoInput from 'components/forms/YesNoInput';
import { connect } from 'react-redux';
import { addErrors, handleChange, listAdd, listDescAdd, clearForm } from 'redux/actions';
import DropdownSingle from 'components/forms/DropdownSingle';
import { withRouter } from 'react-router-dom';
import * as Cookies from 'js-cookie';

import ListInput from 'components/forms/ListInput';
import ListInputDescription from './../../components/forms/ListInputDescription';
import TextareaInput from 'components/forms/TextareaInput';

export class ModifyProfile extends Component {
  constructor (props) {
    super(props);
    this.state = { interests: [], fieldsOfWork: [], salary: [], trainings: [], candidate: {}, candidateLoaded: false, dataLoaded: false };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount () {
    instance.get('/candidate/details').then((s) => {
      this.setState({ candidate: s.data.candidate, candidateLoaded: true });
      this.setDefaults(s.data.candidate, ['first_name', 'last_name', 'email', 'phone_number', 'address', 'postal', 'city', 'bio', 'driver', 'car', 'work_permit', 'student_permit', 'permanence', 'citizen']);
    });
    instance.get('/candidate-data')
      .then((success) => {
        this.setState({
          dataLoaded: true,
          interests: success.data.interests,
          fieldsOfWork: success.data.fieldsOfWork,
          salary: success.data.salary,
          availability: success.data.availability,
          job_type: success.data.job_type,
          trainings: success.data.trainings,
          searching: success.data.seraching
        });
      });
  }

  setDefaults (data, values) {
    values.forEach(val => {
      this.props.handleChange(val, data[val], 'modify-profile');
    });
    this.props.handleChange('searching', parseInt(data.searching), 'modify-profile');
    this.props.handleChange('salary_id', parseInt(data.salary_id), 'modify-profile');
    data.interests.forEach(i => {
      this.props.handleChange('interests', i.pivot.interest_id, 'modify-profile');
    });
    data.job_types.forEach(i => {
      this.props.handleChange('job_type', i.pivot.job_type_id, 'modify-profile');
    });
    data.fields_of_work.forEach(i => {
      this.props.handleChange('field_of_work', i.pivot.field_of_work_id, 'modify-profile');
    });
    data.availabilities.forEach(i => {
      this.props.handleChange('availability', i.pivot.availability_id, 'modify-profile');
    });
    if (data.work_experiences !== undefined && Array.isArray(JSON.parse(data.work_experiences))) {
      var workExperiences = JSON.parse(data.work_experiences);
      workExperiences.forEach(i => {
        this.props.listAdd('work_experiences', i, 'modify-profile');
      });
    }
    data.trainings.forEach(i => {
      this.props.listDescAdd('training', i.pivot.description, parseInt(i.pivot.training_id), 'modify-profile');
    });
  }

  getCV () {
    this.setState({ loading: true });
    instance.get('/candidate/cv', { responseType: 'blob' }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'cv.pdf');
      document.body.appendChild(link);
      link.click();
      this.setState({ loading: false });
    });
  }

  getVideoCV () {
    this.setState({ loading: true });
    instance.get('/candidate/videoCV', { responseType: 'blob' }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', this.state.candidate.video_cv.substr(10));
      document.body.appendChild(link);
      link.click();
      this.setState({ loading: false });
    });
  }

  getLoading () {
    if (this.state.dataLoaded === true && this.state.candidateLoaded === true) {
      return this.state.loading;
    }
  }

  handleSubmit (e) {
    e.preventDefault();
    this.setState({ loading: true });
    var fd = formToFormData(['email', 'password', 'first_name', 'last_name', 'profile_pic', 'phone_number', 'city', 'postal', 'field_of_work', 'interests', 'salary_id', 'availability', 'job_type', 'work_experiences', 'cv', 'video_cv', 'training', 'searching', 'bio', 'driver', 'car', 'work_permit', 'student_permit', 'permanence', 'citizen'], this.props.form);
    fd.append('_method', 'PATCH');
    instance.post('/candidate', fd).then((success) => {
      if (Cookies.get('origin') !== undefined) {
        this.props.history.push(Cookies.get('origin'));
        Cookies.remove('origin');
      } else {
        this.props.history.push('/profile');
      }
      this.props.clearForm('modify-profile');
    }, (error) => {
      this.props.addErrors(error.response.data.errors, 'modify-profile');
    }).then(() => {
      this.setState({ loading: false });
    });
  }

  getRemainingCharacters () {
    if (this.props.form.bio) {
      return 140 - this.props.form.bio.length;
    }
    return 140;
  }

  render () {
    const { t } = this.props;
    const { candidate } = this.state;
    return (
      <Screen type="gray"  loading={!(this.state.dataLoaded && this.state.candidateLoaded) || this.state.loading} title={this.props.t('pages:modifyProfile')}>
        <div className="container">
          <div className="row">

            <Form onSubmit={this.handleSubmit} name="modify-profile" classes="col-lg-8 tw-h-64 tw-mx-auto" classesOverwrite={true}>
              <div className="tw-card tw-relative tw-z-50 tw-bg-red-300 tw-border-red-500 tw-border-4">
                <p>{this.props.t('completeProfile')}</p>
              </div>
              <div className="tw-card tw-mt-3 tw-relative tw-z-50">
                <div className="tw-flex tw-flex-row tw-justify-between tw-items-center">
                  <h2 className="tw-text-xl tw-font-bold">{t('generalInformations')}</h2>
                </div>
                <div className="col-lg-6">
                  <h3 className="tw-text-lg tw-font-semibold">{t('profile_pic')}</h3>
                  <FileInput noTitle={true} name="profile_pic" subtitle={this.props.t('uploadProfilePic') + ' (max 500x500)'} accepted="pdf,docx" required={false} />
                </div>
                <FormGroup>
                  <TextInput name="first_name" />
                  <TextInput name="last_name" />
                </FormGroup>
                <FormGroup>
                  <TextInput name="email" type="email" />
                  <TextInput name="phone_number" />
                </FormGroup>
                <FormGroup>
                  <TextInput required="false" name="postal" />
                  <TextInput required="false" name="city" />
                </FormGroup>
                <TextareaInput required="false" name="bio" />
                <p style={{ textAlign: 'right' }}>{this.getRemainingCharacters()} {this.props.t('remainingC')}</p>

              </div>
              <div className="tw-card tw-mt-3 tw-relative tw-z-40">
                <div className="tw-flex tw-flex-row tw-justify-between tw-items-center">
                  <h2 className="tw-text-xl tw-font-bold">{t('professionalInformations')}</h2>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <DropdownMultiple required="false" name="interests" options={this.state.interests} />
                    <DropdownMultiple required="false" name="field_of_work" options={this.state.fieldsOfWork} />
                    <ListInput name="work_experiences"required="false"/>
                    <ListInputDescription required="false" name="training" options={this.state.trainings} />
                    <YesNoInput name="driver" />
                    <YesNoInput name="car" />
                    <YesNoInput name="student_permit" />

                  </div>
                  <div className="col-lg-6">
                    <DropdownSingle name="salary_id" options={this.state.salary} required="false"/>
                    <DropdownMultiple name="job_type" options={this.state.job_type} required="false" />
                    <DropdownMultiple name="availability" options={this.state.availability} required="false" />
                    <DropdownSingle subtext={this.props.t('searchingText')} name="searching" options={[
                      { id: 1, name_french: 'Oui', name_english: 'Yes' },
                      { id: 0, name_french: 'Non', name_english: 'No' }
                    ]} />
                    <YesNoInput name="work_permit" />
                    <YesNoInput name="permanence" />
                    <YesNoInput name="citizen" />

                  </div>
                </div>
              </div>
              <div className="tw-card tw-mt-3 tw-relative tw-z-30">
                <div className="tw-flex tw-flex-row tw-justify-between tw-items-center">
                  <h2 className="tw-text-xl tw-font-bold">{t('documents')}</h2>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <h3 className="tw-text-lg tw-font-semibold">{t('cvF')}</h3>
                    {(candidate.cv) ? <>
                      <a onClick={() => { this.getCV(); }} className="tw-text-blue-700 tw-underline tw-cursor-pointer tw-mb-3">{t('downloadCV')}</a>
                    </> : <p>{t('noCV')}</p>}
                    <FileInput noTitle={true} name="cv" subtitle={this.props.t('uploadCVFormats')} accepted="pdf,docx" required={false} />

                  </div>
                  <div className="col-lg-6">
                    <h3 className="tw-text-lg tw-font-semibold">{t('video_cv')}</h3>
                    {(candidate.video_cv)
                      ? <a onClick={() => { this.getVideoCV(); }} className="tw-text-blue-700 tw-underline tw-cursor-pointer tw-mb-3">{t('downloadVideoCV')}</a>
                      : <p>{t('noVideoCV')}</p>}
                    <FileInput noTitle={true} name="video_cv" subtitle={this.props.t('uploadVideoCVFormat')} accepted="avi,mp4" required={false} />

                  </div>
                </div>
              </div>
              <div className="tw-card tw-mt-3 tw-relative tw-z-20">
                <div className="tw-flex tw-flex-row tw-justify-between tw-items-center">
                  <h2 className="tw-text-xl tw-font-bold">{t('confirmPasswordToGoForward')}</h2>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <TextInput name="password" type="password" />
                  </div>
                </div>
              </div>
              <div className="tw-flex tw-flex-row tw-justify-end tw-py-3">
                <Submit />
              </div>
            </Form>
          </div>

        </div>
      </Screen>
    );
  }
}

ModifyProfile.propTypes = {
  t: PropTypes.func.isRequired
};

ModifyProfile.defaultProps = {
  form: {}
};

const mapStateToProps = ({ input }) => {
  return {
    form: input['modify-profile']
  };
};

export default withRouter(connect(mapStateToProps, { addErrors, handleChange, listAdd, listDescAdd, clearForm })(withTranslation('common')(ModifyProfile)));
