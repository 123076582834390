import React, { Component } from 'react';
import upload from 'img/icons/upload.svg';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { handleChange } from './../../redux/actions';
import FormContext from 'context/FormContext';

export class FileInput extends Component {
  constructor (props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.getFile = this.getFile.bind(this);
  }

  handleChange (e, context) {
    if (this.props.multiple) {
      this.props.handleChange(this.props.name, e.target.files, context.name);
    } else {
      this.props.handleChange(this.props.name, e.target.files[0], context.name);
    }
  }

  getFile (context) {
    if (this.props.input && this.props.input[context.name] && this.props.input[context.name][this.props.name]) {
      if (this.props.multiple) {
        return Array.from(this.props.input[context.name][this.props.name]).map(f => f.name).join(', ');
      } else {
        return this.props.input[context.name][this.props.name].name;
      }
    } else {
      return this.props.t('noFileSelected');
    }
  }

  getRequired () {
    if (this.props.required === true) {
      return <span className="tw-font-semibold tw-text-red-500 tw-text-sm tw-ml-2">{this.props.t('required')}</span>;
    }
  }

  getContainerStyles () {
    var base = 'tw-flex tw-flex-col tw-w-full tw-mb-3 tw-px-2';
    if (this.props.noTitle && this.props.noTitle === true) {
      base = base + 'tw-px-2';
    }
    return base;
  }

  render () {
    return (
      <FormContext.Consumer>
        {context =>
          <div className={this.getContainerStyles()}>
            {!(this.props.noTitle && this.props.noTitle === true) && <p className="tw-text-sm tw-font-bold tw-leading-none">{(this.props.noTitle && this.props.noTitle === true) ? '' : (this.props.title || this.props.t('common:' + this.props.name))}{this.getRequired()}</p>}
            <p className="tw-font-semibold tw-text-gray-200 tw-text-sm">{this.props.subtitle}</p>
            <div className="tw-relative tw-overflow-hidden tw-flex tw-flex-row tw-items-center">
              <input ref={this.input} onChange={(e) => {
                this.handleChange(e, context);
              }} type="file" id={this.props.name} className="input-file tw-absolute tw-opacity-0 tw-top-0 tw-right-0" multiple={this.props.multiple === true} accept={this.props.accepted} />
              <label htmlFor={this.props.name} className="focus:tw-bg-yellow-700 tw-bg-yellow-500 tw-px-4 tw-py-3 tw-font-bold  tw-text-sm tw-m-1 tw-flex tw-flex-row tw-justify-center tw-items-center tw-leading-none tw-w-32 tw-cursor-pointer focus:tw-shadow-outline focus:tw-outline-none  tw-text-black">
                <img src={upload} alt="Upload Icon" className="tw-h-4 tw-mr-1" />
                {this.props.t('upload')}
              </label>
              <span className="tw-ml-2">{this.getFile(context)}</span>
            </div>
            {this.props.errors && this.props.errors[context.name] && this.props.errors[context.name][this.props.name] && (
              <div className="tw-bg-red-100 tw-mt-2 tw-border-l-4 tw-border-red-500 tw-text-red-700 tw-p-2 tw-text-sm">
                <p>{this.props.errors[context.name][this.props.name]}</p>
              </div>
            )}
          </div>
        }
      </FormContext.Consumer>
    );
  }
}

FileInput.defaultProps = {
  subtitle: '',
  accepted: '',
  required: true
};

FileInput.propTypes = {
  handleChange: PropTypes.func,
  subtitle: PropTypes.string,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  accepted: PropTypes.string,
  required: PropTypes.bool,
  t: PropTypes.func.isRequired
};

const mapStateToProps = ({ errors, input }, ownProps) => {
  return {
    errors: errors,
    input: input
  };
};

export default connect(mapStateToProps, { handleChange })(withTranslation(['input'])(FileInput));
