import React, { Component } from 'react';
import location from 'img/icons/location.svg';
import ReactLoading from 'react-loading';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
export class CompanyBox extends Component {
  getEmployerLogo () {
    const { employer } = this.props;
    if (employer.logo) {
      return <div alt="Profile Picture for Employer" className=" tw-h-24 tw-w-24 tw-bg-cover tw-bg-center">
        <img src={(process.env.REACT_APP_STORAGE_URL + employer.logo).replace(/([^:]\/)\/+/g, '$1')} className=" tw-w-full tw-h-full tw-object-cover" />
      </div>;
    }
    return '';
  }

  getSalesProfile () {
    const { employer } = this.props;
    if (employer.refunds !== undefined && employer.delivery_pickup_conditions !== undefined) {
      return <a href="" className="tw-underline tw-blue-700" onClick={() => this.props.history.push('/policy/' + employer.id)}>{this.props.t('policies')}</a>;
    }
  }

  hasSalesProfile() {
    const { employer } = this.props;
    return employer.refunds !== undefined && employer.delivery_pickup_conditions !== undefined;
  }

  getTitle () {
    const { employer } = this.props;
    if (employer.refunds !== undefined && employer.delivery_pickup_conditions !== undefined) {
      return <p onClick={() => {this.props.history.push('/shop/' + employer.id)}} className="tw-text-xl tw-font-bold tw-underline tw-cursor-pointer hover:tw-no-underline">{ employer.name }</p>;
    } else {
      return <p className="tw-text-xl tw-font-bold">{ employer.name }</p>;
    }
  }

  render () {
    const { employer } = this.props;
    return (
      <div className="col-lg-4">
        <div className="tw-card tw-items-center tw-text-center tw-flex tw-flex-col tw-mb-4">
          {this.props.loading === true
            ? <ReactLoading type="spin" color="#ECC94B" />
            : <>
              {this.getEmployerLogo()}
              {this.getTitle()}
              <p>{ employer.description }</p>
              <div className="tw-items-center tw-flex tw-flex-row tw-self-end tw-mt-2">
                <img className="tw-mr-1" src={location} />
                <span className="tw-text-white tw-font-semibold">{ employer.city }</span>
              </div>
            </>
          }
        </div>
      </div>

    );
  }
}

export default withRouter(withTranslation('common')(CompanyBox));
