import i18n from 'i18next';
import * as Cookies from 'js-cookie';
import { removeOverlay, showOverlay, forceOverlay } from 'redux/actions';
import store from 'redux/store';

export const getLanguage = () => {
  return i18n.language ||
    (typeof window !== 'undefined' && window.localStorage.i18nextLng) ||
    'en';
};

export function objt (obj) {
  if (obj) {
    if (obj.name_french && obj.name_english) {
      if (getLanguage() === 'en') {
        return obj.name_english;
      } else {
        return obj.name_french;
      }
    }
    return obj;
  }
  return undefined;
};

export function formToFormData (fields, form) {
  var fd = new FormData();
  fields.forEach(field => {
    if (field === 'images[]') {
      if (form[field] !== undefined) {
        var images = Array.from(form[field]);
        images.forEach(image => {
          fd.append('images[]', image);
        });
      }
      return;
    }

    if (Array.isArray(form[field])) {
      if (!form[field][0]) {
        return;
      }
      if (Object.keys(form[field][0]).includes('id') && Object.keys(form[field][0]).includes('value') && !Object.keys(form[field][0]).includes('training')) {
        fd.append(field, JSON.stringify(form[field].map(f => f.value)));
        return;
      }
      fd.append(field, JSON.stringify(form[field]) || '');
      return;
    }
    if (form[field] === 0 || parseInt(form[field] === 0)) {
      fd.append(field, form[field]);
    } else {
      fd.append(field, form[field] || '');
    }
  });
  return fd;
}

export function isAuth () {
  if (Cookies.get('auth')) {
    return true;
  }
  return false;
}

export function removeFromCart (id) {
  if (window.localStorage.getItem('cart') !== null) {
    var cartp = JSON.parse(window.localStorage.getItem('cart'));
    if (cartp[id] !== undefined) {
      cartp[id] = cartp[id] - 1;
    }
    window.localStorage.setItem('cart', JSON.stringify(cartp));
  }
}

export function deleteFromCart (id) {
  if (window.localStorage.getItem('cart') !== null) {
    var cartp = JSON.parse(window.localStorage.getItem('cart'));
    cartp[id] = 0;
    window.localStorage.setItem('cart', JSON.stringify(cartp));
  }
}

export function addToCart (id) {
  var cartp = JSON.parse(window.localStorage.getItem('cart'));
  if (cartp === null) {
    window.localStorage.setItem('cart', JSON.stringify({ [id]: 1 }));
  } else {
    if (cartp[id] === undefined) {
      cartp[id] = 1;
    } else {
      cartp[id] = cartp[id] + 1;
    }
    window.localStorage.setItem('cart', JSON.stringify(cartp));
  }
}

export function getQuantityFromCart (id) {
  var cart = window.localStorage.getItem('cart');
  if (cart !== null) {
    var cartp = JSON.parse(cart);
    if (cartp === null || cartp[id] === null || cartp[id] <= 0) {
      return 0;
    }
    return cartp[id];
  }
}

export function emptyCart () {
  window.localStorage.setItem('cart', null);
}

export function isCartEmpty () {
  return JSON.parse(window.localStorage.getItem('cart')) === null;
}

export function getCart () {
  return JSON.parse(window.localStorage.getItem('cart'));
}

export function getAmountInCart () {
  var cart = window.localStorage.getItem('cart');
  if (cart !== null) {
    var total = 0;
    var cartp = JSON.parse(cart);
    if (cartp === null) {
      return 0;
    }
    Object.keys(cartp).forEach(k => {
      total = total + cartp[k];
    });
    return total + getAmountInCartVariant();
  }
  return 0 + getAmountInCartVariant();
}

// VARIANT

export function removeFromCartVariant (id) {
  if (window.localStorage.getItem('cartVariant') !== null) {
    var cartp = JSON.parse(window.localStorage.getItem('cartVariant'));
    if (cartp[id] !== undefined) {
      cartp[id] = cartp[id] - 1;
    }
    window.localStorage.setItem('cartVariant', JSON.stringify(cartp));
  }
}

export function deleteFromCartVariant (id) {
  if (window.localStorage.getItem('cartVariant') !== null) {
    var cartp = JSON.parse(window.localStorage.getItem('cartVariant'));
    cartp[id] = 0;
    window.localStorage.setItem('cartVariant', JSON.stringify(cartp));
  }
}

export function addToCartVariant (id) {
  var cartp = JSON.parse(window.localStorage.getItem('cartVariant'));
  if (cartp === null) {
    window.localStorage.setItem('cartVariant', JSON.stringify({ [id]: 1 }));
  } else {
    if (cartp[id] === undefined) {
      cartp[id] = 1;
    } else {
      cartp[id] = cartp[id] + 1;
    }
    window.localStorage.setItem('cartVariant', JSON.stringify(cartp));
  }
}

export function getQuantityFromCartVariant (id) {
  var cart = window.localStorage.getItem('cartVariant');
  if (cart !== null) {
    var cartp = JSON.parse(cart);
    if (cartp === null || cartp[id] === null || cartp[id] <= 0) {
      return 0;
    }
    return cartp[id];
  }
}

export function emptyCartVariant () {
  window.localStorage.setItem('cartVariant', null);
}

export function isCartVariantEmpty () {
  return JSON.parse(window.localStorage.getItem('cartVariant')) === null;
}

export function getCartVariant () {
  return JSON.parse(window.localStorage.getItem('cartVariant'));
}

export function getAmountInCartVariant () {
  var cart = window.localStorage.getItem('cartVariant');
  if (cart !== null) {
    var total = 0;
    var cartp = JSON.parse(cart);
    if (cartp === null) {
      return 0;
    }
    Object.keys(cartp).forEach(k => {
      total = total + cartp[k];
    });
    return total;
  }
  return 0;
}

export function changeMode (id, mode) {
  var modes = JSON.parse(window.localStorage.getItem('modes'));
  if (modes !== null) {
    modes[id] = mode;
  } else {
    modes = {};
    modes[id] = mode;
  }
  window.localStorage.setItem('modes', JSON.stringify(modes));
}

export function getMode (id) {
  var modes = JSON.parse(window.localStorage.getItem('modes'));
  if (modes !== null) {
    if (modes[id]) {
      return modes[id];
    }
  }
  return null;
}

export function getModes () {
  return JSON.parse(window.localStorage.getItem('modes'));
}

export function changeModeVariant (id, mode) {
  var modes = JSON.parse(window.localStorage.getItem('modesVariant'));
  if (modes !== null) {
    modes[id] = mode;
  } else {
    modes = {};
    modes[id] = mode;
  }
  window.localStorage.setItem('modesVariant', JSON.stringify(modes));
}

export function getModeVariant (id) {
  var modes = JSON.parse(window.localStorage.getItem('modesVariant'));
  if (modes !== null) {
    if (modes[id]) {
      return modes[id];
    }
  }
  return null;
}

export function getModesVariant () {
  return JSON.parse(window.localStorage.getItem('modesVariant'));
}

export function authOverlay () {
  if (isAuth()) {
    return true;
  } else {
    store.dispatch(showOverlay());
    return false;
  }
}

export function closeOverlay () {
  store.dispatch(removeOverlay());
}

export function forceOpenOverlay () {
  if (isAuth()) {
    return true;
  } else {
    store.dispatch(forceOverlay());
    return false;
  }
}
