/* eslint no-unused-vars: 0 */
import React, { Component } from 'react';
import * as Cookies from 'js-cookie';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

export function PrivateRoute ({ component: Component, email, props, reverse = false, ...rest }) {
  if (reverse) {
    if (rest.location.search === '?disconnect') {
      Cookies.remove('auth');
    }
    return (
      <Route
        {...rest}

        render={({ location }) =>
          (Cookies.get('auth') === undefined) ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: '/',
                state: { from: location }
              }}
            />
          )
        }
      />
    );
  }

  if (Cookies.get('auth') === undefined || (email === true && Cookies.get('email_verified') === 'false')) {
    Cookies.set('origin', rest.location.pathname);
  }

  return (
    <Route
      {...rest}
      render={({ location }) =>
        (Cookies.get('auth') !== undefined) ? (
          (email === true && Cookies.get('email_verified') === 'false') ? (
            <Redirect
              to={{
                pathname: '/verify-email',
                state: { from: location }
              }}
            />
          ) : (<Component {...props} />)
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

PrivateRoute.propTypes = {
  component: PropTypes.node.isRequired,
  reverse: PropTypes.bool
};

export default PrivateRoute;
